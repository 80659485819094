import { Tooltip } from 'antd';
import { ethers } from 'ethers';
import { useCheckConnectedWallet } from 'hooks/player/useCheckConnectedWallet';
import { useGetPlayer } from 'hooks/player/useGetPlayer';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import useConnectors from 'hooks/useConnectors';
import { useLogOut } from 'hooks/useLogOut';
import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { linkWallet } from 'services/api';
import signMessage from 'services/Contracts/signMessage';
import { LocalhostStorage } from 'utils/sessionStorage';
import {
  ButtonWrapper,
  Description,
  LinkButton,
  StyledModal,
  SubmitButton,
} from './styled';

export const LinkWalletModal = ({
  onRefresh,
  refresh,
}: { onRefresh?: () => void; refresh?: boolean } = {}) => {
  const { account, active } = useActiveWeb3React();
  const { disconnect } = useConnectors();

  const [loading, setLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { playerInfo } = useGetPlayer(null, refresh);

  const [params, setParams] = useState<{
    walletAddress?: string;
  }>({
    walletAddress: account,
  });
  const { isConnected, loading: checkLoading } = useCheckConnectedWallet(
    params,
    params,
  );

  const logOut = useLogOut();

  const handleLinkWalletToPlayer = async () => {
    const typeOfConnector = LocalhostStorage.get('typeOfConnector');
    console.log('typeOfConnector', typeOfConnector);
    if (loading) {
      return;
    }
    setLoading(true);
    setIsOpen(false);
    let signMess;
    if (typeOfConnector === 'Metamask') {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const dataSignature = await signMessage(account, provider.provider);
      signMess = dataSignature;
    } else {
      setLoading(false);
      disconnect();
      return;
    }

    if (!signMess?.success) {
      setLoading(false);
      setIsOpen(true);
      toast.error('User denied message signature.');
      return;
    }

    const linkWalletRes = await linkWallet({
      walletAddress: account,
      signature: signMess.data.signature,
      message: signMess.data.message,
    });
    setLoading(false);

    if (linkWalletRes?.success) {
      toast.success('Connect wallet to player successfully');
      // setIsOpen(false);
      if (onRefresh) {
        onRefresh();
      }
    } else {
      toast.error(linkWalletRes?.message || 'Connect wallet to player failed');
      setIsOpen(true);
    }
  };

  useEffect(() => {
    if (active && account && playerInfo && !playerInfo.walletAddress) {
      setParams({ walletAddress: account });
    }
  }, [active, account, playerInfo]);

  useEffect(() => {
    if (
      active &&
      account &&
      playerInfo &&
      !playerInfo.walletAddress &&
      !checkLoading
    ) {
      if (isConnected) {
        toast.error(
          'The wallet address has been already connected to other player',
        );
        logOut();
      } else {
        setIsOpen(true);
      }
    }
  }, [active, account, playerInfo, isConnected, checkLoading]);

  const onDisconnect = () => {
    setIsOpen(false);
    disconnect();
  };

  return (
    <>
      <StyledModal isOpen={isOpen} width="500px" onCancel={onDisconnect}>
        {isConnected ? (
          <div>
            <Description>
              Your account is not connected to any wallets. Wallet address{' '}
              <Tooltip title={account}>
                <b>
                  <span>{`${account?.substring(0, 6)}...${account?.substring(
                    38,
                  )}`}</span>
                </b>
              </Tooltip>{' '}
              has been already connected to other player. Please disconnect or
              switch to other wallets
            </Description>
            <ButtonWrapper>
              <SubmitButton height="40px" width="150px" onClick={onDisconnect}>
                Disconnect
              </SubmitButton>
            </ButtonWrapper>
          </div>
        ) : (
          <div>
            <Description>
              Your account is not connected to any wallets. If you want to
              connect your account to the wallet address{' '}
              <Tooltip title={account}>
                <b>
                  <span>{`${account?.substring(0, 6)}...${account?.substring(
                    38,
                  )}`}</span>
                </b>
              </Tooltip>
              , please click on Confirm button and sign message on the next step
            </Description>

            <ButtonWrapper>
              <SubmitButton
                height="40px"
                width="150px"
                onClick={handleLinkWalletToPlayer}
              >
                Confirm
              </SubmitButton>
              <LinkButton height="40px" width="150px" onClick={onDisconnect}>
                Disconnect
              </LinkButton>
            </ButtonWrapper>
          </div>
        )}
      </StyledModal>
    </>
  );
};
