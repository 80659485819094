import { Top5Table, Top5TableRow, Top5Title, Top5Wrapper } from './styled';
import Top1Icon from 'assets/main/vison/top1-icon.svg';
import Top2Icon from 'assets/main/vison/top2-icon.svg';
import Top3Icon from 'assets/main/vison/top3-icon.svg';
import Top4Icon from 'assets/main/vison/top4-icon.svg';
import Top5Icon from 'assets/main/vison/top5-icon.svg';
import AvataDefault from 'assets/main/vison/avata-default.png';
import { useGetTopEarners } from 'hooks/landingpage/useGetTopEarners';

export const TopEarners = () => {
  const { topEarners, loading } = useGetTopEarners();

  return (
    <Top5Wrapper>
      <Top5Title>TOP 5 EARNERS</Top5Title>
      <Top5Table>
        {topEarners.map((earner, index) => (
          <Top5TableRow key={index}>
            <div className="reward">
              <img
                src={
                  index === 0
                    ? Top1Icon
                    : index === 1
                    ? Top2Icon
                    : index === 2
                    ? Top3Icon
                    : index === 3
                    ? Top4Icon
                    : Top5Icon
                }
                alt=""
              />
            </div>
            <img
              className="avatar"
              src={earner.avatarURL || AvataDefault}
              alt=""
            />
            <div className="username">{earner.username}</div>
            <div className="revenue">
              ${' '}
              {earner.accumulatedRevenue.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }) || 0}
            </div>
          </Top5TableRow>
        ))}
      </Top5Table>
    </Top5Wrapper>
  );
};
