import { Button, Checkbox, Form, Modal, Upload } from 'antd';
import styled from 'styled-components';
import { ReactComponent as BigaLogo } from 'assets/images/BIGA-logo.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { ReactComponent as Congratulation } from 'assets/images/register/congratulation-text.svg';
import { Input } from 'components/Elements/Input';
import { media } from 'constants/enum/breakpoints';

export const Wrapper = styled.div`
  text-align: center;
  background: linear-gradient(to bottom, #161923, #0f0f13);
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  position: relative;
  min-height: 700px;
  box-sizing: border-box;

  ${media.md2} {
    min-height: 400px;
    background: none;
  }

  ${media.xs} {
    padding: 0 10px;
  }
`;

export const BigaLogoWrapper = styled(BigaLogo)`
  margin-bottom: 20px;
  cursor: pointer;
`;

export const CongratulationText = styled(Congratulation)``;

export const WrapperImage = styled.img`
  width: 50px;
  height: 50px;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

export const SuccessWrapper = styled.div``;

export const RegisterStepTitle = styled.div`
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const RegisterStepButton = styled(Button)`
  font-size: 20px;
  border-radius: 50px;
  font-weight: 700;
  background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
    0px 2px 4px -1px rgba(10, 70, 82, 0.12),
    0px 16px 24px 0px rgba(0, 187, 255, 0.24),
    0px 8px 8px -4px rgba(0, 187, 255, 0.12);
  border: none;
  color: #fff;
  display: flex;
  width: 100%;
  height: 58px;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;

  ${media.sm} {
    font-size: 16px;
  }
`;

export const RegisterStepDescription = styled.div`
  font-family: 'Neue Haas Grotesk Display Pro';
  text-align: center;
  color: #a1a1a5;
  font-size: 12px;
  font-weight: 700;
`;

export const RegisterStepLink = styled.div<{ disabled?: boolean }>`
  color: #00aafd;
  font-family: 'Neue Haas Grotesk Display Pro';
  text-decoration-line: underline;
  font-size: 12px;
  font-weight: 700;

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  :hover,
  :focus {
    color: #fff;
  }
`;

export const RegisterFirstStepWrapper = styled(Wrapper)`
  padding: 4.19rem 1.94rem 3.25rem;
  max-width: 100%;
`;

export const RegisterFirstStepFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
`;

export const RegisterFirstStepButtonWrapper = styled.div`
  margin: 2.5rem 0.5rem 1.5rem;
`;

export const RegisterBackButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  margin-bottom: 1.5rem;

  color: #191919;
  text-align: center;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  cursor: pointer;
`;

export const FormWrapper = styled.div`
  width: 100%;

  .ant-input-affix-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    :focus,
    :active,
    :focus-visible {
      border: 1px solid rgba(0, 0, 0, 0.08);
      box-shadow: none;
    }
    :hover {
      border: 1px solid rgba(0, 0, 0, 0.08);
      box-shadow: none;
    }
  }
  .ant-form-item-explain-error {
    color: #ff3b3b;
    text-align: right;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: pre-wrap;
  }

  .ant-form-item {
    margin: 0;
  }
  .ant-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .ant-input-prefix {
    width: 16px;
  }

  .ant-input {
    border: none;
    border-radius: 0;
    background: #111115;
    color: #fff;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input:hover {
    border: 1px solid rgba(10, 56, 133, 0.08);
    background: #111115;
    color: #fff;
    box-shadow: none;
  }

  .ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled
    ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled
    ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    border: 1px solid rgba(10, 56, 133, 0.08);
    box-shadow: none;
    outline: none;
    background: rgba(10, 56, 133, 0.04);
    input {
      border: none;
      background: transparent;
    }
  }

  .g-recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }

  ${media.md2} {
    input:autofill,
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    input:-webkit-autofill:visited {
      -webkit-box-shadow: 0 0 0 30px #050511 inset;
      background-color: #050511;
      color: #fff;
      -webkit-text-fill-color: #fff;
    }
  }

  svg {
    cursor: pointer;
  }
`;

export const CustomFormItem = styled(Form.Item)`
  border: none;
`;

export const TextError = styled.p`
  color: #ff3b3b;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
`;

export const TextErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > img {
    cursor: pointer;
  }
`;

export const CenterTextError = styled(TextError)`
  text-align: center;
  white-space: pre-wrap;
`;

export const BackText = styled.div`
  white-space: pre-wrap;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #a1a1a5;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 30px;
  margin-top: 10px;

  & > .button-link-wrapper {
    flex: 1;
    display: flex;
  }

  & > .button-link-wrapper:first-of-type {
    justify-content: flex-end;
  }

  & > .button-link-wrapper:last-of-type {
    justify-content: flex-start;
  }

  & > .button-link-wrapper:first-of-type:after,
  & > .button-link-wrapper:last-of-type:before {
    content: '';
    display: inline-block;
  }
`;

export const RegisterStepButtonWrapper = styled.div`
  margin-top: 0.5rem;
`;

export const RegisterConnectText = styled.div`
  color: #a9a9a9;
  text-align: center;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  white-space: nowrap;

  margin: 1.5rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;

  .inline-line {
    width: -webkit-fill-available;
    height: 0.0625rem;
    background: #191919;
  }
`;

export const RegisterConnectButton = styled.div<{ disabled?: boolean }>`
  flex: 1;
  font-size: 18px;
  border-radius: 12px;
  font-weight: 500;
  background: #fff;
  border: none;
  color: #000;
  display: flex;
  height: 52px;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;
  column-gap: 10px;

  &.metamask-btn {
    color: #fff;
    background: #000;
  }

  img {
    width: 24px;
  }

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  .anticon-loading {
    color: rgba(0, 0, 0, 0.2);
    font-size: 1.125rem;
  }

  span {
    white-space: nowrap;
  }

  ${media.sm} {
    font-size: 16px;
  }
`;

export const RegisterConnectButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 12px;
  box-sizing: border-box;
`;

export const RegisterConnectedInfo = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1rem;
  justify-content: center;
  align-items: center;

  color: #1d1d1d;
  text-align: center;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  img {
    width: 24px;
  }
`;

export const RegisterVerifyCodeStepHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  align-items: center;
  gap: 1.5rem;
`;

export const RegisterVerifyCodeWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  ${media.sm} {
    justify-content: flex-start;
  }
`;

export const RegisterVerifyCodeBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const RegisterStepBlueLink = styled(Button)`
  color: #fff;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 12px 10px;
  background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
    0px 2px 4px -1px rgba(10, 70, 82, 0.12),
    0px 16px 24px 0px rgba(0, 187, 255, 0.24),
    0px 8px 8px -4px rgba(0, 187, 255, 0.12);
  border-radius: 50px;
  flex: 1;
  border: none;
  height: 58px;

  ${media.sm} {
    font-size: 16px;
  }
`;

export const RegisterVerifyCodeStepTimer = styled.div`
  color: #a1a1a5;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const RegisterVerifyCodeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
`;

export const RegisterVerifyCodeInput = styled.input`
  border: 1px solid #1e1e1ef0;
  background: #111115;
  padding: 0.625rem 1rem;
  text-align: center;
  border-radius: 8px;
  width: 54px;
  height: 54px;
  aspect-ratio: 1 / 1;

  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--biga-blue);
  outline: none;

  ${media.xl1} {
    width: 47px;
  }

  ${media.lg} {
    font-size: 1.3rem;
    padding: 0.25rem 0.5rem;
  }
`;

export const RegisterSetupProfileHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 23rem;
`;

export const RegisterSetupProfileWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  ${media.sm} {
    justify-content: flex-start;
  }
`;

export const RegisterUploadProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  color: rgba(25, 25, 25, 0.4);
  text-align: center;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: transparent;
`;

export const RegisterUploadProfileDragger = styled(Upload.Dragger)`
  &.ant-upload.ant-upload-drag {
    background: none;
    border: none;
  }

  &.ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
`;

export const RegisterUploadProfileDragAndDrop = styled.div`
  display: flex;
  width: 150px;
  height: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #727277;
  font-size: 14px;
  font-weight: 500;
  border: 1px dashed #1e1e1ef0;
  background: #111115;
  row-gap: 8px;
  border-radius: 1px;

  svg {
    fill: #727277;
  }
`;

export const RegisterSetupProfileInput = styled(Input)`
  .ant-input {
    text-align: center;
  }
`;

export const RegisterUploadedImageFrame = styled.div`
  display: flex;
  width: 6.25rem;
  height: 6.25rem;
  padding: 0.625rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #fff;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  position: relative;

  .anticon-close-circle {
    scale: 1.5;
    top: -0.5rem;
    right: -0.5rem;
    position: absolute;
    :hover,
    :focus {
      scale: 1.6;
    }
  }
`;

export const RegisterSetupProfileForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .ant-form-item-explain-error {
    color: #ff3b3b;
    text-align: center;
    font-family: 'Neue Haas Grotesk Display Pro';
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: pre-wrap;
  }

  .ant-form-item {
    margin: 0;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input:hover {
    border: 1px solid rgba(10, 56, 133, 0.08);
    background: #111115;
    color: #fff;
    box-shadow: none;
  }
  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 1px solid rgba(10, 56, 133, 0.08);
    box-shadow: none;
    outline: none;
  }
`;

export const RegisterCongratulationWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;

  img {
    max-width: 100%;
  }

  ${media.sm} {
    justify-content: flex-start;
  }
`;

export const RegisterCongratulationHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  max-width: 23rem;
`;

export const CheckboxWrapper = styled(Checkbox)`
  &.ant-checkbox-input {
    width: 20px;
    height: 20px;
    background: none;
  }
  .ant-checkbox + span {
    word-spacing: 2px;
    padding-left: 16px;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Neue Haas Grotesk Display Pro';
    text-align: left;
  }

  &.ant-checkbox-wrapper {
    padding-left: 0 1px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #00aafd;
  }

  .ant-checkbox .ant-checkbox-inner {
    border-color: #00aafd;
    background: none;
  }

  a {
    color: #00aafd;
    font-weight: 500;
    text-decoration-line: underline;
  }

  ${media.xs} {
    .ant-checkbox + span {
      padding-left: 8px;
    }
  }
`;

export const GradientTop = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;

  ${media.md2} {
    display: none;
  }
`;

export const CloseIconWrapper = styled(CloseIcon)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;

  ${media.md2} {
    display: none;
  }
`;

export const ReCAPTCHAWrapper = styled.div`
  width: 100%;
`;

export const ModalContainer = styled(Modal)`
  .ant-modal-title {
    font-size: 24px;
  }
  .ant-modal-footer {
    display: flex;
    gap: 20px;

    .ant-btn {
      flex: 1;
      font-size: 20px;
      border-radius: 12px;
      border: none;
      font-weight: 700;
      height: 54px;

      &-primary {
        background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
        box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
          0px 2px 4px -1px rgba(10, 70, 82, 0.12),
          0px 16px 24px 0px rgba(0, 187, 255, 0.24),
          0px 8px 8px -4px rgba(0, 187, 255, 0.12);
      }

      &-default {
        background: #cccccc;
        box-shadow: 0px 8px 8px -4px rgba(255, 255, 255, 0.12);
      }
    }
  }
`;
