import { useEffect, useState } from 'react';
import { getGameActive, IGameActiveQuery } from 'services/api';

import { IGameActive } from './useGetGameActive';

export const useGetGameActiveForScroll = (
  params: IGameActiveQuery,
  refresh?: boolean,
) => {
  const [gameActive, setGameActive] = useState<IGameActive[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    void (async () => {
      setLoading(true);
      if (!total || params.pageIndex <= page || gameActive?.length < total) {
        const res = await getGameActive(params);
        if (res?.success) {
          if (params.pageIndex > page) {
            setGameActive([...gameActive, ...(res?.payload?.data?.data ?? [])]);
          } else {
            setGameActive(res?.payload?.data?.data);
          }
          setTotal(res?.payload?.data?.totalCount);
          setPage(params.pageIndex);
        }
      }

      setLoading(false);
    })();
  }, [params, refresh]);
  return {
    gameActive,
    loading,
    total,
  };
};
