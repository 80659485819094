import { Tooltip } from 'antd';
import { useState, useEffect } from 'react';
import { IGameActive } from 'hooks/games/useGetGameActive';
import useWindowSize from 'hooks/useWindowSize';

import {
  GameDetailBodyWrapper,
  GameDetailFooterWrapper,
  GameDetailHeaderWrapper,
  GameDetailHeaderImage,
  GameDetailWrapper,
  GameLevelTitleWrapper,
  GameLevelWrapper,
  GameNameWrapper,
  Line,
  Parallelogram,
  ParallelogramDetail,
  ParallelogramDetailValue,
  ParallelogramWrapper,
  PlayButton,
  ViewButton,
  GameDetailButtonWrapper,
} from './styled';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector } from 'redux/store';

export const GameItem = (props: IGameActive & { showDetail: () => void }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { isLoggedIn } = useAppSelector(state => state.player);

  const handleMouseMove = (event: React.MouseEvent) => {
    const { left, top, right, bottom } =
      event.currentTarget.getBoundingClientRect();
    const { clientX, clientY } = event;

    if (
      clientX >= left &&
      clientX <= right &&
      clientY >= top &&
      clientY <= bottom &&
      !showMobileMenu
    ) {
      setIsHovered(true);
    } else {
      setIsHovered(false);
    }
  };

  useEffect(() => {
    if (width <= 767) setShowMobileMenu(true);
    else setShowMobileMenu(false);
  }, [width, setShowMobileMenu]);

  return (
    <>
      <GameDetailWrapper
        onClick={() =>
          showMobileMenu ? props?.showDetail && props?.showDetail() : {}
        }
      >
        <GameDetailHeaderWrapper
          onMouseEnter={() => {
            showMobileMenu ? setIsHovered(false) : setIsHovered(true);
          }}
          onMouseLeave={() => setIsHovered(false)}
          onMouseMove={handleMouseMove}
        >
          <GameDetailHeaderImage
            src={props?.backgroundImgUrl}
            isHovered={isHovered}
          />
          {isHovered && (
            <>
              <GameDetailFooterWrapper>
                <ParallelogramWrapper>
                  <Parallelogram>
                    <ParallelogramDetail>Total revenue</ParallelogramDetail>
                  </Parallelogram>
                  <Parallelogram small>
                    <ParallelogramDetailValue>
                      $
                      {Number(props?.totalRevenue || 0).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          useGrouping: true,
                        },
                      )}
                    </ParallelogramDetailValue>
                  </Parallelogram>
                </ParallelogramWrapper>
                <ParallelogramWrapper>
                  <Parallelogram>
                    <ParallelogramDetail>
                      Total unique players
                    </ParallelogramDetail>
                  </Parallelogram>
                  <Parallelogram small>
                    <ParallelogramDetailValue>
                      {props?.totalPlayers.toLocaleString() || 0}
                    </ParallelogramDetailValue>
                  </Parallelogram>
                </ParallelogramWrapper>

                <ParallelogramWrapper>
                  <Parallelogram>
                    <ParallelogramDetail>Total attempts</ParallelogramDetail>
                  </Parallelogram>
                  <Parallelogram small>
                    <ParallelogramDetailValue>
                      {props?.totalAttempts
                        .toLocaleString('de-DE')
                        .replace(/\./g, ' ') || 0}
                    </ParallelogramDetailValue>
                  </Parallelogram>
                </ParallelogramWrapper>
              </GameDetailFooterWrapper>
              <GameDetailButtonWrapper>
                <PlayButton
                  onMouseEnter={() => {
                    showMobileMenu ? setIsHovered(false) : setIsHovered(true);
                  }}
                  onMouseLeave={() => {
                    showMobileMenu ? setIsHovered(false) : setIsHovered(true);
                  }}
                  onClick={() => {
                    if (isLoggedIn) {
                      navigate(`/${props?.gameId}`);
                    } else {
                      toast.error('Please log in to play the game!');
                    }
                  }}
                >
                  Play
                </PlayButton>
                <ViewButton
                  onMouseEnter={() => {
                    showMobileMenu ? setIsHovered(false) : setIsHovered(true);
                  }}
                  onMouseLeave={() => {
                    showMobileMenu ? setIsHovered(false) : setIsHovered(true);
                  }}
                  onClick={() => {
                    props?.showDetail && props?.showDetail();
                    setIsHovered(false);
                  }}
                >
                  View
                </ViewButton>
              </GameDetailButtonWrapper>
            </>
          )}
        </GameDetailHeaderWrapper>

        <GameDetailBodyWrapper>
          <Tooltip title={props?.name}>
            <GameNameWrapper
              onClick={() => props?.showDetail && props?.showDetail()}
            >
              {props.name}
            </GameNameWrapper>
          </Tooltip>
          {/* <GameLevelWrapper>
            <Tooltip title={props?.tags?.join(' / ')}>
              <GameLevelTitleWrapper>
                {props?.tags?.join(' / ')}
              </GameLevelTitleWrapper>
            </Tooltip>
          </GameLevelWrapper> */}
        </GameDetailBodyWrapper>
      </GameDetailWrapper>
    </>
  );
};
