import bigaArcadeLogo from 'assets/vectors/BIGA.svg';
import { Link } from 'react-router-dom';
import {
  LandingFooterContentContactUs,
  LandingFooterContentPolicyLinksColumn,
  LandingFooterContentPolicyLinksWrapper,
  LandingFooterContentSocialLinksColumn,
  LandingFooterContentSocialLinksWrapper,
  LandingFooterContentWrapper,
  LandingFooterMailWrapper,
  LandingFooterSectionWrapper,
} from './styled';

import { ReactComponent as Discord } from 'assets/icons/discord.svg';
import { ReactComponent as Instagram } from 'assets/icons/instagram.svg';
import { ReactComponent as Mail } from 'assets/icons/mail.svg';
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg';

export const LandingFooterSection = () => {
  const termsLink = `${process.env.REACT_APP_HOST}/termsandconditions.pdf`;
  const policyLink = `${process.env.REACT_APP_HOST}/privacy-policy.pdf`;

  return (
    <LandingFooterSectionWrapper>
      <LandingFooterContentWrapper>
        <LandingFooterContentContactUs>
          <img src={bigaArcadeLogo} alt="biga-arcade" />
          <div>
            <p>Our mission is to give one million gamers,</p>
            <p>a reliable $1000 a month income</p>
          </div>
        </LandingFooterContentContactUs>

        <LandingFooterContentPolicyLinksWrapper className="desktop">
          <LandingFooterContentPolicyLinksColumn>
            <a
              className="blur-link terms-and-conditions"
              href={termsLink}
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>
            <a
              className="blur-link privacy-policy"
              href={policyLink}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </LandingFooterContentPolicyLinksColumn>
        </LandingFooterContentPolicyLinksWrapper>

        <LandingFooterContentSocialLinksWrapper>
          <LandingFooterMailWrapper>
            <Link to="mailto:hello@bigarcade.org" className="mail-to-contact">
              hello@bigarcade.org
            </Link>
          </LandingFooterMailWrapper>
          <LandingFooterContentSocialLinksColumn>
            <Link to="https://twitter.com/bigaarcade" target="_blank">
              <Twitter className="twitter" />
            </Link>
            <Link to="https://www.instagram.com/bigaarcade/" target="_blank">
              <Instagram className="instagram" />
            </Link>
            <Link to="https://discord.gg/nGcGdS7tT3" target="_blank">
              <Discord className="discord" />
            </Link>
            <Link to="mailto:hello@bigarcade.org" target="_blank">
              <Mail className="mail" />
            </Link>
          </LandingFooterContentSocialLinksColumn>
        </LandingFooterContentSocialLinksWrapper>

        <LandingFooterContentPolicyLinksWrapper className="mobile">
          <LandingFooterContentPolicyLinksColumn>
            <a
              className="blur-link"
              href={termsLink}
              target="_blank"
              rel="noreferrer"
            >
              Terms and Conditions
            </a>
            <a
              className="blur-link"
              href={policyLink}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </LandingFooterContentPolicyLinksColumn>
        </LandingFooterContentPolicyLinksWrapper>
      </LandingFooterContentWrapper>
    </LandingFooterSectionWrapper>

    // <LandingFooterBottomDiv></LandingFooterBottomDiv>
  );
};
