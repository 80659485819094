import { BannerWrapper } from './styled';

export const TopBanner = () => {
  return (
    <BannerWrapper>
      Notice:
      <br /> BIGA Arcade is in Public Alpha. All scores and revenue figures are
      for simulation purposes only.
    </BannerWrapper>
  );
};
