import { Button, Divider } from 'antd';
import { ReactComponent as Gradient } from 'assets/images/profile/gradient.svg';
import { ReactComponent as GradientMobile } from 'assets/images/profile/gradient-mobile.svg';
import { Input } from 'components/Elements/Input';
import styled from 'styled-components';
import { ReactComponent as Metamask } from 'assets/images/profile/metamask-text.svg';
import { media } from 'constants/enum/breakpoints';
import { EditOutlined } from '@ant-design/icons';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const ProfileWrapper = styled.div`
  width: 100%;
  padding: 20px 0;
  background-color: #0f0f13;
  position: relative;
  overflow: hidden;

  ${media.md} {
    padding: 60px 0;
  }
`;

export const ProfileContentWrapper = styled.div`
  position: relative;
  gap: 48px;
  display: flex;
  flex-direction: column;
  z-index: 50;
  margin: 0 100px;

  ${media.xl1} {
    margin: 0 40px 0 60px;
  }

  ${media.md} {
    margin: 0 30px;
  }

  ${media.sm} {
    margin: 0 11px;
  }
`;

export const ProfileAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 20px;
`;

export const AvatarWrapper = styled.div`
  position: relative;
  border-radius: 11px;
  border: 1.4px solid #404040;
  padding: 2px;
`;

export const Avatar = styled.img`
  user-select: none;
  border-radius: 11px;
  cursor: pointer;
  width: 80px;
  height: 80px;
`;

export const Username = styled(Text)`
  color: #fff;
  font-size: 22px;
  font-weight: 500;

  ${media.sm} {
    display: none;
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
  justify-content: left;
  color: #00aafd;
  font-size: 12px;
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }

  ${media.sm} {
    display: none;
  }
`;

export const LogoutMobileWrapper = styled.div`
  display: none;
  column-gap: 4px;
  align-items: center;
  justify-content: center;
  color: #00aafd;
  font-size: 18px;
  font-weight: 400;
  text-decoration-line: underline;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }

  ${media.sm} {
    display: flex;
  }
`;

export const ProfileInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${media.md} {
    flex-direction: column;
    row-gap: 30px;
  }
`;

export const InfoLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: left;
  width: 25%;

  ${media.xl} {
    width: 30%;
  }

  ${media.lg} {
    width: 40%;
  }

  ${media.md} {
    width: 100%;
    align-items: center;
  }
`;

export const InfoRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 75%;
  justify-content: left;
  max-width: 881px;

  ${media.xl} {
    width: 70%;
  }

  ${media.lg} {
    width: 60%;
  }

  ${media.md} {
    width: 100%;
  }
`;

export const InfoRightWrapperForScroll = styled.div<{
  isEmpty?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  height: ${props => (props.isEmpty ? '100%' : '350px')};
  max-height: 100%;
`;

export const InfoTitle = styled(Text)`
  font-size: 24px;
  font-weight: 500;
  line-height: 44px;
  width: 40%;
  text-align: left;

  ${media.lg} {
    width: 100%;
  }

  ${media.sm} {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: #e8e8e8;
  }
`;

export const PageTitle = styled(Text)`
  font-size: 32px;
  font-weight: 700;
  text-align: left;
  line-height: 44px;
  width: 40%;

  ${media.md} {
    text-align: center;
    width: 100%;
  }

  ${media.sm} {
    font-size: 24px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  max-width: 100%;
  padding: 30px 40px;
  background-color: #15151b;
  border-radius: 24px;
  gap: 20px;

  ${media.lg} {
    flex-direction: column;
  }

  ${media.sm} {
    padding: 0;
    background: none;
  }
`;

export const GameDetailRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const RevenueHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex: 1;
`;

export const StyledDivider = styled(Divider)`
  border-bottom: none;
  border-color: #332f2f;
  margin: 10px 0;
`;

export const GameStasItem = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.lg} {
    &.revenue-history {
      flex-direction: column;
    }
  }
`;

export const FormWrapper = styled.div`
  flex-direction: column;
  display: flex;
  width: 60%;
  row-gap: 30px;
  padding: 10px 40px 0;

  &.game-stats-wrapper {
    row-gap: 10px;
  }

  .input-wrapper {
    border: 1px solid #1e2028;
    background-color: #1f1f26;

    input {
      background-color: #1f1f26;
    }

    &::placeholder {
      color: #1f1f26;
    }

    svg {
      path {
        fill: #929bad;
      }
    }
  }

  .ant-form-item {
    margin: 0 0 20px;
  }

  input:autofill,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  input:-webkit-autofill:visited {
    -webkit-box-shadow: 0 0 0 30px #1f1f26 inset;
    background-color: #1f1f26;
  }

  ${media.xl1} {
    padding: 10px 0 0;
  }

  ${media.lg} {
    width: 100%;
  }
  ${media.md} {
    background-color: #15151b;
    padding: 20px;
    border-radius: 23px;
  }
`;

export const FormItemWrapper = styled.div`
  flex-direction: column;
  display: flex;
  margin: 20px 0;
`;

export const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.game-revenue-wrapper {
    align-items: flex-end;
    gap: 0px;
  }

  &.game-name-wrapper {
    gap: 0px;
  }
`;

export const UnderlineText = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  text-decoration-line: underline;
  cursor: pointer;
  user-select: none;
  color: #00aafd;
`;

export const InfoTitleText = styled(Text)`
  color: #a9a9a9;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  word-wrap: break-word;

  &.white {
    color: #fff;
  }

  &.font-size-16px {
    font-size: 16px;
  }

  &.font-size-14px {
    font-weight: 700;
    font-size: 14px;
  }

  &.font-size-22px {
    font-weight: 700;
    font-size: 22px;
    text-align: center;
  }

  &.margin-bottom-10px {
    margin-bottom: 10px;
  }

  ${media.sm} {
    color: #fff;

    &.font-weight-400-sm {
      font-weight: 400;
    }

    &.font-size-22px {
      font-size: 20px;
    }
  }
`;

export const LevelWrapper = styled.div`
  display: flex;
  gap: 4px;

  .level {
    color: #929bad;
    font-size: 12px;
    font-weight: 500;
  }

  .id {
    color: #ffa14a;
    font-size: 12px;
    font-weight: 500;
  }

  .win {
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    color: #00aafd;
    padding: 2px 6px;
  }
`;

export const WinLabel = styled.span`
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  background-color: #00aafd;
  padding: 2px 6px;
  border-radius: 2px;
  width: fit-content;
  text-align: right;
`;

export const BalanceText = styled.div`
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid #2e2e2e;
  padding: 8px 30px;
`;

export const StyledInput = styled(Input)`
  ${media.sm} {
    font-size: 14px;
  }
`;

export const UsernameText = styled.div`
  font-size: 22px;
  border: 1px solid #1e2028;
  background-color: #1f1f26;
  border-radius: 8px;
  color: #fff;
  padding: 0 38px;
  align-items: center;
  display: flex;
  height: 54px;
  font-weight: 500;
  cursor: pointer;
  ${media.sm} {
    font-size: 16px;
  }
`;

export const EmailText = styled(UsernameText)`
  font-size: 14px;
  color: #929bad;
  padding: 0 40px;
`;

export const WithdrawWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

export const BIGAValue = styled(Text)`
  font-size: 18px;
  font-weight: 500;

  &.game-revenue {
    font-size: 20px;
    font-weight: 700;
  }
`;

export const BIGAInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .spin-revenue-table {
    width: 100%;
  }
`;

export const RevenueValue = styled(Text)`
  font-size: 22px;
  font-weight: 700;
  color: #e961ff;
  border: 1px solid #2e2e2e;
  padding: 8px 30px;
  border-radius: 6px;

  ${media.sm} {
    font-weight: 400;
  }
`;

export const DeleteButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${media.sm} {
    justify-content: center;
  }
`;

export const DeleteButton = styled(Text)`
  padding: 12px 24px;
  border-radius: 12px;
  border: none;
  background: linear-gradient(90deg, #ff0000 -54.32%, #e50000 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(255, 0, 0, 0.24),
    0px 2px 4px -1px rgba(139, 0, 0, 0.12),
    0px 16px 24px 0px rgba(255, 0, 0, 0.24),
    0px 8px 8px -4px rgba(139, 0, 0, 0.12);

  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
`;

export const CollapseSaveButtonWrapper = styled.div`
  display: flex;

  &.modal-btn-wrapper {
    justify-content: center;
  }
`;

export const CollapseSaveButton = styled(Button)`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  border-radius: 12px;
  line-height: normal;
  padding: 12px 24px;
  background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
    0px 2px 4px -1px rgba(10, 70, 82, 0.12),
    0px 16px 24px 0px rgba(0, 187, 255, 0.24),
    0px 8px 8px -4px rgba(0, 187, 255, 0.12);
  font-size: 16px;
  font-weight: 600;
  user-select: none;
  height: auto;
  outline: 0;
  border: none;
  width: 100%;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    background: #ffffff08;
    opacity: 0.4;
    outline: 0;
    box-shadow: none;
    color: #ffffff66;
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #ffffff66;
    border: none;
    background: #ffffff08;
    opacity: 0.4;
    outline: 0;
    box-shadow: none;
  }

  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover {
    border: none;
    background: #ffffff08;
    color: #ffffff66;
  }

  &.modal-btn {
    width: 139px;
  }
`;

export const ShowMoreButton = styled(CollapseSaveButton)`
  width: 192px;
`;

export const AddButton = styled(CollapseSaveButton)`
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 7px;

  svg {
    width: 8px;
    height: 8px;
  }
`;

export const ConnectButton = styled(Button)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  border-radius: 12px;
  background-color: #fff;
  border: none;
  width: 191px;
  padding: 0 23px;

  &.black {
    background-color: #000;
    width: 100%;

    svg circle {
      fill: white;
    }
  }
`;

export const LoadMoreButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px 0;

  span {
    color: #929bad;
    font-weight: 500;
    font-size: 12px;
  }

  ${media.lg} {
    flex-direction: column;
    row-gap: 12px;
    align-items: center;
  }
`;
export const LoadMoreText = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: 600;
`;

export const Gradient1 = styled(Gradient)`
  position: absolute;
  top: -10%;
  left: 15%;
  width: 70%;
  z-index: 0;

  ${media.sm} {
    display: none;
  }
`;

export const Gradient2 = styled(Gradient)`
  position: absolute;
  top: 25%;
  right: -10%;
  z-index: 0;
  width: 70%;

  ${media.sm} {
    display: none;
  }
`;

export const Gradient3 = styled(GradientMobile)`
  display: none;
  position: absolute;
  top: 1100px;
  right: -190px;
  z-index: 51;
  width: 400px;

  ${media.sm} {
    display: block;
  }
`;

export const MetamaskText = styled(Metamask)`
  position: absolute;
  top: 15px;
  left: 25px;
`;

export const EditIcon = styled(EditOutlined)`
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
  pointer-events: none;
  background-color: rgba(7, 7, 9, 0.2);
  padding: 3px;
  border-radius: 2px;
`;
