import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

export const Top5Wrapper = styled.div`
  z-index: 2;
  ${media.xl} {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  ${media.sm} {
    position: relative;
    top: -200px;
  }
`;

export const Top5Title = styled.div`
  font-size: 28px;
  color: white;
  font-weight: bold;
  padding: 20px;
  text-align: center;

  ${media.sm} {
    padding-left: 0;
    width: 90%;
    text-align: left;
    font-size: 24px;
  }
`;

export const Top5Table = styled.div`
  background-color: rgba(249, 249, 249, 0.08);
  min-height: 428px;
  min-width: 423px;
  max-width: 700px;
  width: 90%;
  backdrop-filter: blur(4px);
  padding: 30px 50px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 20px;
    padding: 1px;
    background: linear-gradient(
      to bottom right,
      rgba(0, 0, 0, 0.2),
      rgba(252, 247, 247, 0.4)
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

export const Top5TableRow = styled.div`
  color: white;
  display: flex;
  border-bottom: 1px solid rgba(235, 240, 255, 0.08);
  align-items: center;
  column-gap: 10px;
  padding: 10px 0;

  * {
    color: #d9d9d9;
  }

  &:last-child {
    border-bottom: unset;
  }
  .username {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat Medium';
  }
  .revenue {
    font-weight: 500;
    font-size: 16px;
    font-family: 'Gilroy Medium';
  }
  .reward {
    position: relative;
    img {
      width: 40px;
      height: 40px;
      position: relative;
    }
    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 25px;
      height: 25px;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #d9d9d9;
      margin-top: 1px;
    }
  }

  img.avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }

  ${media.sm} {
    zoom: 1.5;
  }
`;
