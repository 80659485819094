import {
  LeftCircleTwoTone,
  PoweroffOutlined,
  RightCircleTwoTone,
  SolutionOutlined,
} from '@ant-design/icons';
import { Dropdown } from 'antd';
import defaultProfileImg from 'assets/images/default-user-profile.png';
import bigalogo from 'assets/vectors/logo-navbar-biga.svg';
import { PlayerRoles } from 'constants/enum/PlayerRole';
import { PlayerStatus } from 'constants/enum/PlayerStatus';
import { PitRouter } from 'constants/routers';
import useEnableCashier from 'hooks/cashier/useEnableCashier';
import { useLogOut } from 'hooks/useLogOut';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setCollapsed } from 'redux/slices/navbar';
import { useAppSelector } from 'redux/store';

import {
  // SettingWrapper,
  MenuLogoSetting,
  DropdownItemWrapper,
  DropdownWrapper,
  Line,
  Logo,
  LogoWrapper,
  MainWrapper,
  MenuItemWrapper,
  MenuLogoAdminPanel,
  MenuLogoAdminStats,
  MenuLogoAffiliate,
  MenuLogoCashier,
  MenuLogoGameManagement,
  MenuLogoGames,
  MenuLogoLeaderboard,
  MenuLogoStaking,
  MenuText,
  MenuWrapper,
  NavbarWrapper,
  ProfileAvatar,
  ProfileAvatarWrapper,
  ProfileMain,
  ProfileWrapper,
  SupportLogo,
  SupportWrapper,
  ArrowRightIcon,
  NameItemWrapper,
  Username,
  UsernameWrapper,
  ProfileContainer,
  IconItemWrapper,
} from './styled';
import { LogoutModal } from 'components/Modals/UserProfile/LogoutModal';

interface INavbarProps {
  theme?: string;
  onClick?: () => void;
}

export const Navbar = ({ theme, onClick }: INavbarProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const logOut = useLogOut();
  const [visible, setVisible] = useState(false);
  const [visibleAvatarDropdown, setVisibleAvatarDropdown] = useState(false);
  const [isShowDetailNavbar, setIsShowDetailNavbar] = useState<boolean>(false);
  const dispatch = useDispatch();
  const collapsed = useAppSelector(state => state.navbar.collapsed);

  const menuWrapperRef = useRef<HTMLDivElement>(null);
  const menuDetailWrapperRef = useRef<HTMLDivElement>(null);

  const handleScroll = (source: HTMLDivElement, target: HTMLDivElement) => {
    if (source.scrollTop !== target.scrollTop) {
      target.scrollTop = source.scrollTop;
    }
  };

  useEffect(() => {
    const menuWrapper = menuWrapperRef.current;
    const menuDetailWrapper = menuDetailWrapperRef.current;

    const syncScrollFromMenuWrapper = () => {
      if (menuWrapper && menuDetailWrapper) {
        handleScroll(menuWrapper, menuDetailWrapper);
      }
    };

    const syncScrollFromDetailWrapper = () => {
      if (menuDetailWrapper && menuWrapper) {
        handleScroll(menuDetailWrapper, menuWrapper);
      }
    };

    menuWrapper?.addEventListener('scroll', syncScrollFromMenuWrapper);
    menuDetailWrapper?.addEventListener('scroll', syncScrollFromDetailWrapper);

    return () => {
      menuWrapper?.removeEventListener('scroll', syncScrollFromMenuWrapper);
      menuDetailWrapper?.removeEventListener(
        'scroll',
        syncScrollFromDetailWrapper,
      );
    };
  }, []);

  const isEnableCashier = useEnableCashier();

  const handleOk = () => {
    logOut();
    navigate(PitRouter.GAME_LIST);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleLogOutClick = () => {
    setVisibleAvatarDropdown(false);
    setVisible(true);
  };

  const handleProfileClick = () => {
    setVisibleAvatarDropdown(false);
    navigate(PitRouter.PROFILE);
  };

  const widgetMenu = (
    <DropdownWrapper>
      <DropdownItemWrapper key="profile" onClick={handleProfileClick}>
        <SolutionOutlined />
        Profile
      </DropdownItemWrapper>
      <DropdownItemWrapper key="logout" onClick={handleLogOutClick}>
        <PoweroffOutlined />
        Logout
      </DropdownItemWrapper>
    </DropdownWrapper>
  );

  const { playerInfo, refreshAvatar } = useAppSelector(state => state.player);
  const avatarURL = useMemo(() => {
    return playerInfo?.avatarURL
      ? `${playerInfo?.avatarURL}?timestamp=${Date.now()}`
      : '';
  }, [playerInfo?.avatarURL, refreshAvatar]);

  return (
    <NavbarWrapper onClick={onClick}>
      <LogoWrapper>
        <Logo
          src={bigalogo}
          alt="logo"
          onClick={() => navigate(PitRouter.HOME)}
        />
      </LogoWrapper>

      <MainWrapper className={isShowDetailNavbar ? 'expanded' : ''}>
        <ArrowRightIcon
          className={isShowDetailNavbar ? 'expanded' : ''}
          onClick={() => setIsShowDetailNavbar(!isShowDetailNavbar)}
        />

        {playerInfo && playerInfo.status === PlayerStatus.UploadedProfile && (
          <ProfileContainer>
            <ProfileWrapper
              onClick={() => setIsShowDetailNavbar(false)}
              to={PitRouter.PROFILE}
              className={pathname === PitRouter.PROFILE ? 'navbar-active' : ''}
            >
              {/* <Dropdown
                open={visibleAvatarDropdown}
                overlay={widgetMenu}
                trigger={['click']}
                placement="bottomRight"
                overlayStyle={{ position: 'fixed' }}
                onOpenChange={open => setVisibleAvatarDropdown(open)}
              > */}
              <ProfileMain>
                <ProfileAvatarWrapper>
                  <ProfileAvatar
                    src={avatarURL ? avatarURL : defaultProfileImg}
                    alt="avatar profile"
                  />
                </ProfileAvatarWrapper>
                {/* <LogoutModal
                    isOpen={visible}
                    onCancel={handleCancel}
                    onOk={handleOk}
                  /> */}
              </ProfileMain>
              {/* </Dropdown> */}
              {isShowDetailNavbar && (
                <UsernameWrapper>
                  <Username>{playerInfo.username}</Username>
                </UsernameWrapper>
              )}
            </ProfileWrapper>
          </ProfileContainer>
        )}

        <MenuWrapper ref={menuWrapperRef}>
          <MenuItemWrapper
            onClick={() => setIsShowDetailNavbar(false)}
            to={PitRouter.GAME_LIST}
            className={pathname === PitRouter.GAME_LIST ? 'navbar-active' : ''}
          >
            <IconItemWrapper>
              <MenuLogoGames />
            </IconItemWrapper>

            <NameItemWrapper
              className={`games ${isShowDetailNavbar ? 'expanded' : ''}`}
            >
              Games
            </NameItemWrapper>
          </MenuItemWrapper>
          <Line />

          {playerInfo &&
            playerInfo.roles &&
            !playerInfo.roles.includes(PlayerRoles.Tester) && (
              <>
                <MenuItemWrapper
                  onClick={() => setIsShowDetailNavbar(false)}
                  to={PitRouter.LEADER_BOARD}
                  className={
                    pathname === PitRouter.LEADER_BOARD ? 'navbar-active' : ''
                  }
                >
                  <IconItemWrapper>
                    <MenuLogoLeaderboard />
                  </IconItemWrapper>
                  <NameItemWrapper
                    className={`leaderboard ${
                      isShowDetailNavbar ? 'expanded' : ''
                    }`}
                  >
                    Leaderboards
                  </NameItemWrapper>
                </MenuItemWrapper>
                <Line />
              </>
            )}

          {playerInfo?.roles &&
            playerInfo.roles.includes(PlayerRoles.Influencer) && (
              <>
                <MenuItemWrapper
                  onClick={() => setIsShowDetailNavbar(false)}
                  to={PitRouter.AFFILIATE}
                  className={
                    pathname === PitRouter.AFFILIATE ? 'navbar-active' : ''
                  }
                >
                  <IconItemWrapper>
                    <MenuLogoAffiliate />
                  </IconItemWrapper>
                  <NameItemWrapper
                    className={`affiliate ${
                      isShowDetailNavbar ? 'expanded' : ''
                    }`}
                  >
                    Affiliate
                  </NameItemWrapper>
                </MenuItemWrapper>
                <Line />
              </>
            )}

          {isEnableCashier &&
            playerInfo?.status === PlayerStatus.UploadedProfile &&
            !playerInfo?.roles.includes(PlayerRoles.Tester) && (
              <>
                <MenuItemWrapper
                  onClick={() => setIsShowDetailNavbar(false)}
                  to={PitRouter.CASHIER}
                  className={
                    pathname === PitRouter.CASHIER ? 'navbar-active' : ''
                  }
                >
                  <IconItemWrapper>
                    <MenuLogoCashier />
                  </IconItemWrapper>
                  <NameItemWrapper
                    className={`cashier ${
                      isShowDetailNavbar ? 'expanded' : ''
                    }`}
                  >
                    Cashier
                  </NameItemWrapper>
                </MenuItemWrapper>
                <Line />
              </>
            )}

          {playerInfo?.roles &&
            (playerInfo.roles.includes(PlayerRoles.SuperAdmin) ||
              playerInfo.roles.includes(PlayerRoles.Admin) ||
              playerInfo.roles.includes(PlayerRoles.Provider)) && (
              <>
                <MenuItemWrapper
                  onClick={() => setIsShowDetailNavbar(false)}
                  to={PitRouter.MANAGEMENT}
                  className={
                    pathname === PitRouter.MANAGEMENT ? 'navbar-active' : ''
                  }
                >
                  <IconItemWrapper>
                    <MenuLogoGameManagement />
                  </IconItemWrapper>
                  <NameItemWrapper
                    className={`game-management ${
                      isShowDetailNavbar ? 'expanded' : ''
                    }`}
                  >
                    Management
                  </NameItemWrapper>
                </MenuItemWrapper>
                <Line />
              </>
            )}

          {playerInfo?.roles &&
            (playerInfo.roles.includes(PlayerRoles.SuperAdmin) ||
              playerInfo.roles.includes(PlayerRoles.Admin)) && (
              <>
                <MenuItemWrapper
                  onClick={() => setIsShowDetailNavbar(false)}
                  to={PitRouter.ADMIN_STATS}
                  className={
                    pathname === PitRouter.ADMIN_STATS ? 'navbar-active' : ''
                  }
                >
                  <IconItemWrapper>
                    <MenuLogoAdminStats />
                  </IconItemWrapper>
                  <NameItemWrapper
                    className={`admin-stats ${
                      isShowDetailNavbar ? 'expanded' : ''
                    }`}
                  >
                    Admin stats
                  </NameItemWrapper>
                </MenuItemWrapper>
                <Line />
              </>
            )}
          {playerInfo?.roles &&
            (playerInfo.roles.includes(PlayerRoles.SuperAdmin) ||
              playerInfo.roles.includes(PlayerRoles.Admin)) && (
              <>
                <MenuItemWrapper
                  onClick={() => setIsShowDetailNavbar(false)}
                  to={PitRouter.ADMIN_PANEL}
                  className={
                    pathname === PitRouter.ADMIN_PANEL ? 'navbar-active' : ''
                  }
                >
                  <IconItemWrapper>
                    <MenuLogoAdminPanel />
                  </IconItemWrapper>
                  <NameItemWrapper
                    className={`admin-panel ${
                      isShowDetailNavbar ? 'expanded' : ''
                    }`}
                  >
                    Admin panel
                  </NameItemWrapper>
                </MenuItemWrapper>
                <Line />
              </>
            )}
          {process.env.REACT_APP_IS_HIDE_STAKING !== 'true' && (
            <>
              <MenuItemWrapper
                onClick={() => setIsShowDetailNavbar(false)}
                to={PitRouter.STAKING}
                className={
                  pathname === PitRouter.STAKING ? 'navbar-active' : ''
                }
              >
                <IconItemWrapper>
                  <MenuLogoStaking />
                </IconItemWrapper>
                <NameItemWrapper
                  className={`staking ${isShowDetailNavbar ? 'expanded' : ''}`}
                >
                  Staking
                </NameItemWrapper>
              </MenuItemWrapper>
              <Line />
            </>
          )}
        </MenuWrapper>

        {!playerInfo?.roles.includes(PlayerRoles.Tester) && (
          <>
            <MenuItemWrapper
              onClick={() => setIsShowDetailNavbar(false)}
              to={PitRouter.SUPPORT}
              className={pathname === PitRouter.SUPPORT ? 'navbar-active' : ''}
            >
              <IconItemWrapper>
                <SupportLogo />
              </IconItemWrapper>
              <NameItemWrapper
                className={`FAQs ${isShowDetailNavbar ? 'expanded' : ''}`}
              >
                FAQs
              </NameItemWrapper>
            </MenuItemWrapper>
          </>
        )}
      </MainWrapper>
    </NavbarWrapper>
  );
};
