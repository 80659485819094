import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

export const BannerWrapper = styled.div`
  width: 100%;
  background-color: rgba(233, 97, 255, 0.5);
  margin-top: 25px;
  font-size: 18px;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-style: normal;
  font-weight: 500;
  text-align: center;
  z-index: 100;
  padding: 9px 20px;
  color: rgba(255, 255, 255, 1);

  br {
    display: none;
  }

  ${media.md} {
    margin-top: 30px;
    padding: 9px 10px;
    font-size: 15px;
    br {
      display: block;
    }
  }
`;
