import { StockOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { ReactComponent as LogoAdminPanel } from 'assets/vectors/logo-navbar-admin-panel.svg';
import { ReactComponent as LogoAffiliate } from 'assets/vectors/logo-navbar-affiliate.svg';
import { ReactComponent as LogoCashier } from 'assets/vectors/logo-navbar-cashier.svg';
import { ReactComponent as LogoGameManagement } from 'assets/vectors/logo-navbar-game-management.svg';
import { ReactComponent as LogoGames } from 'assets/vectors/logo-navbar-games.svg';
import { ReactComponent as LogoHelp } from 'assets/vectors/logo-navbar-help.svg';
import { ReactComponent as LogoLeaderboard } from 'assets/vectors/logo-navbar-leaderboard.svg';
import { ReactComponent as LogoStaking } from 'assets/vectors/logo-navbar-staking.svg';
import { ReactComponent as LogoSetting } from 'assets/vectors/logo-navbar-setting.svg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export interface DefaultTheme {
  collapsed: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div<{ theme: DefaultTheme }>`
  color: #000;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
  display: ${props => props.theme.collapsed && 'none'};
  @media (max-width: 1440px) {
    display: none;
  }
`;

export const NavbarWrapper = styled(Wrapper)<{ theme: DefaultTheme }>`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  display: flex;

  .navbar-collapse-icon {
    display: none;
  }

  :hover,
  :focus {
    .navbar-collapse-icon {
      display: block;
    }
  }
`;

export const LogoWrapper = styled(Wrapper)<{ theme: DefaultTheme }>`
  padding: 15px 0 36px 39px;
`;

export const Logo = styled.img<{ theme: DefaultTheme }>`
  width: 106px;
  height: 43px;
  cursor: pointer;
`;

export const Line = styled.span<{ theme: DefaultTheme }>`
  width: 24px;
  height: 1px;
  opacity: 0.1;
  align-self: center;
  background: #e4e4e4;
  margin-bottom: 25px;
`;

export const MainWrapper = styled(Wrapper)`
  background: #15151b;
  border-radius: 20px;
  height: 52px;
  width: 95vw;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 6px 4px;
  flex-direction: row;
`;

export const MenuLogoGames = styled(LogoGames)`
  width: 28px;
  height: 28px;

  &:hover path {
    fill: rgba(255, 29, 0, 1);
  }
`;

export const MenuLogoStaking = styled(LogoStaking)`
  width: 28px;
  height: 28px;

  &:hover path {
    fill: rgba(157, 224, 113, 1);
  }
`;

export const MenuLogoLeaderboard = styled(LogoLeaderboard)`
  width: 26px;
  height: 26px;

  &:hover path {
    fill: rgba(255, 195, 0, 1);
  }
`;
export const MenuLogoCashier = styled(LogoCashier)`
  width: 28px;
  height: 28px;

  &:hover path {
    fill: rgba(70, 76, 253, 1);
  }
`;
export const MenuLogoGameManagement = styled(LogoGameManagement)`
  width: 23px;
  height: 23px;

  &:hover {
    stroke: rgba(255, 29, 0, 1);
    path,
    polygon {
      fill: rgba(255, 29, 0, 1);
    }
  }
`;
export const MenuLogoAdminPanel = styled(UserOutlined)`
  font-size: ${props => (props.theme.collapsed ? '28px' : '20px')};
  color: #fff;

  &:hover {
    color: rgba(70, 76, 253, 1);
  }
`;

export const MenuLogoSetting = styled(LogoSetting)`
  width: 23px;
  height: 23px;

  > g {
    opacity: 1;
  }

  &:hover g g g {
    path,
    circle {
      stroke: rgba(255, 195, 0, 1);
    }
  }
`;

export const MenuLogoAffiliate = styled(LogoAffiliate)`
  font-size: ${props => (props.theme.collapsed ? '28px' : '24px')};
  color: #000;

  &:hover path {
    fill: rgba(157, 224, 113, 1);
  }
`;

export const MenuLogoAdminStats = styled(StockOutlined)`
  font-size: ${props => (props.theme.collapsed ? '28px' : '22px')};
  color: #fff;

  &:hover {
    color: rgba(255, 195, 0, 1);
  }
`;

export const MenuText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
`;

export const MenuItemWrapper = styled(Link)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  align-self: ${props => props.theme.collapsed && 'stretch'};
  justify-content: ${props => props.theme.collapsed && 'center'};
  gap: 8px;
  cursor: pointer;
  align-self: stretch;
  justify-content: center;
  margin-bottom: 25px;
  border-radius: 18px;
  padding: 10px 0px;

  &:focus,
  &.navbar-active {
    background: linear-gradient(93deg, #0038ff -49.51%, #00aafd 99.71%);
    box-shadow: 0px 6px 12px 0px rgba(0, 187, 255, 0.24);
  }
`;

export const ProfileWrapper = styled(Wrapper)<{ theme: DefaultTheme }>`
  width: 100%;
  padding: 22px 18px 31px 18px;
  align-items: ${props => (props.theme.collapsed ? 'center' : 'flex-start')};
  flex-direction: ${props => props.theme.collapsed && 'column'};
  justify-content: ${props => props.theme.collapsed && 'center'};
  gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;

export const ProfileMain = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const ProfileAvatarWrapper = styled.div`
  display: flex;
  padding: 3px;
  align-items: flex-start;
  gap: 10.909px;
  border-radius: 10px;
  border: 1px solid #404040;
  width: 60px;
  height: 60px;
`;

export const ProfileAvatar = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 12px;
`;

export const ProfileText = styled(Text)`
  font-size: 18px;
  font-weight: 600;
`;

export const SupportText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
`;

export const SupportWrapper = styled(Link)<{ theme: DefaultTheme }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  justify-content: ${props => props.theme.collapsed && 'center'};

  cursor: pointer;
  user-select: none;
  &:hover,
  &:focus,
  &.navbar-active {
    .logo-navbar {
      fill: #00aafd;
    }

    .logo-navbar-stroke {
      stroke: #0a3885;
    }

    ${SupportText} {
      color: #0a3885;
    }
  }
`;

export const SupportLogo = styled(LogoHelp)<{ theme: DefaultTheme }>`
  width: 22px;
  height: 22px;

  > g {
    opacity: 1;
  }

  &:hover g path {
    stroke: rgba(255, 29, 0, 1);
  }
`;

export const DropdownWrapper = styled(Menu)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 200px;
`;

export const DropdownItemWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  padding: 10px;
  cursor: pointer;
  user-select: none;

  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;

  &:last-child {
    border-top: 1px solid #ccc;
  }

  &:hover {
    background: rgba(10, 56, 133, 0.08);
  }
`;

export const CollapseButton = styled.div`
  position: absolute;
  right: -6px;
  top: 80px;

  & > .navbar-collapse-icon {
    scale: 1.6;
    color: #000;
  }

  @media (max-width: 1440px) {
    display: none;
  }
`;
