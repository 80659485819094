// eslint-disable-next-line simple-import-sort/imports
import { Col, Dropdown, Row, Select, Spin } from 'antd';
import { GameDetailDrawer } from 'components/Games/GameDetailDrawer';
import LeaderboardTable from 'components/Leaderboard';
import { Leaderboard as CongratsLeaderboardPopup } from 'components/Modals/PlayGame/Leaderboard';
import { LeaderboardTableGame } from 'components/Modals/PlayGame/LeaderboardTable';
import { LevelMaster as CongratsLevelMasterPopup } from 'components/Modals/PlayGame/LevelMaster';
import { GameRecommentItem } from 'components/PlayGame/GameRecommentItem';
import { GameScoringModelType } from 'constants/enum/GameScoringModelType';
import { GameWebSignalToListen } from 'constants/enum/GameWebSignal';
import { ScoringModels } from 'constants/enum/ScoringModel';
import { PitRouter } from 'constants/routers';
import { IGameActive, useGetGameActive } from 'hooks/games/useGetGameActive';
import { useGetGameActiveInfo } from 'hooks/games/useGetGameActiveInfo';
import { useGetGameLevelDetail } from 'hooks/leaderboard/useGetGameLevelDetail';
import { useGetGameStatistic } from 'hooks/leaderboard/useGetGameStatic';
import { useGetListLevel } from 'hooks/leaderboard/useGetListLevel';
import { useGetRecommendedGame } from 'hooks/play-game/useGetRecommendedGame';
import { IGameWebSignalWonLevel } from 'interfaces/dto/socketDTO';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import { refreshNewToken } from 'redux/slices/refreshToken';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { oneTimeToken } from 'services/api';
import { Socket, io } from 'socket.io-client';
import HomepageTemplate from 'template/Homepage';
import { convertScoringModelToDisplayType } from 'utils/convertScoringModelToDisplayType';
import { LocalhostStorage } from 'utils/sessionStorage';
import bigaCreditIcon from 'assets/vectors/logo-header-biga-credit.svg';

import { SelectMultiLevelMinisize } from '../Leaderboard/styled';
import {
  BackButton,
  CloseButton,
  GameDetailContentMainWrapper,
  GameDetailContentTitleWrapper,
  GameDetailContentWrapper,
  GameDetailStatisticContent,
  GameDetailStatisticDescription,
  GameDetailStatisticGameName,
  GameDetailStatisticInfo,
  GameDetailStatisticInfoTag,
  GameDetailStatisticInfoTags,
  GameDetailStatisticItem,
  GameDetailStatisticItemTitle,
  GameDetailStatisticItemValue,
  GameDetailStatisticItems,
  GameDetailStatisticWrapper,
  GameDetailWrapper,
  GameRecommentText,
  GameRecommentWrapper,
  GameStyledCol,
  GameTitleStyled,
  PlayGameContainer,
  PlayGameWrapper,
  WarningText,
} from './styled';
import { formatUnits } from 'ethers/lib/utils';
import { GameItem } from 'components/Games/GameItem';
import { LeaderBoardButton } from './LeaderBoardButton';
import { Desktop } from 'styles/Common/HeaderDesktop.styled';

const socketUrl = process.env.REACT_APP_GAME_SOCKET_URL;

export const PlayGame = () => {
  const [socket, setSocket] = useState<Socket>();
  const [gameDetail, setGameDetail] = useState<IGameActive>();
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [tokenLoading, setTokenLoading] = useState<boolean>(false);
  const [shareTwitterURL, setShareTwitterURL] = useState<string>('');
  const [saveOneTimeToken, setSaveOneTimeToken] = useState<string>('');
  const [saveActiveVersion, setSaveActiveVersion] = useState<string>('');
  const [isDisconnecting, setIsDisconnecting] = useState<boolean>(false);
  const [params] = useState({
    pageIndex: 1,
    pageSize: 30,
    showStatistic: true,
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const pathname = window.location.pathname;
  const gameId = pathname.substring(1);

  const { gameActive } = useGetGameActive(params);
  const { gameInfo, loading } = useGetGameActiveInfo(gameId);

  const isLoggedIn = Boolean(LocalhostStorage.get('accessToken'));
  const player = useAppSelector(state => state.player);

  const selectedGame = gameActive?.find(game => game?.gameId === gameId);

  const { recommendedGameList } = useGetRecommendedGame(gameId);

  const toggleDrawer = useToggleModal(ApplicationModal.GAME_DETAIL_DRAWER);

  const toggleLeaderboardModal = useToggleModal(
    ApplicationModal.PLAYGAME_LEADERBOARD,
  );
  const toggleLevelMasterModal = useToggleModal(
    ApplicationModal.PLAYGAME_LEVEL_MASTER,
  );
  const isLeaderboardOpen = useModalIsOpen(
    ApplicationModal.PLAYGAME_LEADERBOARD,
  );
  const isLevelMasterOpen = useModalIsOpen(
    ApplicationModal.PLAYGAME_LEVEL_MASTER,
  );

  const showDetail = (index: number) => {
    setGameDetail(recommendedGameList?.at(index));
    toggleDrawer();
  };

  const connect = async (
    accessToken: string,
    tokenType: string,
    gameId: string,
  ): Promise<boolean> => {
    console.log('call connect to socket');
    setIsConnecting(true);
    const socket = io(`${socketUrl}?gameId=${gameId}`, {
      auth: {
        Authorization: `Bearer ${accessToken}`,
        Type: tokenType,
      },
      transports: ['websocket'],
    });
    return await new Promise(resolve => {
      socket.on('connect', () => {
        setSocket(socket);
        setIsConnected(true);
        setIsConnecting(false);
        socket.on('disconnect', () => {
          setSocket(null);
          setIsConnected(false);
          setIsConnecting(false);
          setIsDisconnecting(false);
        });
        resolve(true);
      });
    });
  };

  const on = (type: string, callback: (data: any) => void) => {
    if (socket) {
      socket.on(type, callback);
    }
  };

  const disconnect = () => {
    if (socket) {
      console.log('disconnect socket');
      setIsDisconnecting(true);
      socket.disconnect();
    }
  };

  useEffect(() => {
    if (gameId && gameInfo === null && !loading) {
      navigate(PitRouter.REGISTER);
    }
  }, [gameId, gameInfo, loading, navigate]);

  useEffect(() => {
    const fetchOneTimeToken = async () => {
      try {
        setTokenLoading(true);
        const response = await oneTimeToken({ gameId });
        if (response?.success) {
          setSaveOneTimeToken(response?.payload?.data?.token);
          setSaveActiveVersion(response?.payload?.data?.activeVersion);
        } else {
          navigate(PitRouter.REGISTER);
          toast.error('Please log in to play the game!');
        }
        setTokenLoading(false);
      } catch (error) {
        console.error(error);
        setTokenLoading(false);
      }
    };

    if (gameId) {
      fetchOneTimeToken();
    }
  }, [gameId, navigate]);

  useEffect(() => {
    if (
      LocalhostStorage.get('accessToken') &&
      player?.playerInfo &&
      gameId &&
      !isConnected &&
      !isConnecting
    ) {
      connect(
        LocalhostStorage.get('accessToken'),
        LocalhostStorage.get('tokenType'),
        gameId,
      ).then(connected => {
        if (connected) {
          //
        }
      });
    }
  }, [gameId, player?.playerInfo, isConnected, isConnecting]);

  useEffect(() => {
    return () => {
      if (isConnected && !isConnecting && !isDisconnecting) {
        disconnect();
      }
    };
  }, [isConnected && !isConnecting && !isDisconnecting]);

  useEffect(() => {
    if (isConnected) {
      console.log('connected socket');
      on('pong', () => {
        console.log('listened to signal pong');
      });
      on(GameWebSignalToListen.PLAYED_LEVEL, () => {
        console.log('listened to signal ' + GameWebSignalToListen.PLAYED_LEVEL);
        dispatch(refreshNewToken());
      });
      on(GameWebSignalToListen.WON_LEVEL, (data: IGameWebSignalWonLevel) => {
        console.log('listened to signal ' + GameWebSignalToListen.WON_LEVEL);
        if (
          !isLevelMasterOpen &&
          !isLeaderboardOpen &&
          data?.game?.gameId === gameId
        ) {
          setShareTwitterURL(
            data.twitterShareURL?.replace(
              '{{url}}',
              location.origin + PitRouter.REGISTER,
            ),
          );
          toggleLevelMasterModal();
        }
      });
      on(
        GameWebSignalToListen.ENTERED_LEADERBOARD,
        (data: IGameWebSignalWonLevel) => {
          console.log(
            'listened to signal ' + GameWebSignalToListen.ENTERED_LEADERBOARD,
          );
          if (
            !isLevelMasterOpen &&
            !isLeaderboardOpen &&
            data?.game?.gameId === gameId
          ) {
            setShareTwitterURL(
              data.twitterShareURL?.replace(
                '{{url}}',
                location.origin + PitRouter.REGISTER,
              ),
            );
            toggleLeaderboardModal();
          }
        },
      );
    }
  }, [isConnected, gameId]);

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, []);

  return (
    <HomepageTemplate>
      <PlayGameWrapper>
        <PlayGameContainer>
          <GameDetailWrapper>
            <GameDetailContentWrapper>
              <GameDetailContentTitleWrapper>
                <BackButton
                  style={{ zIndex: 3 }}
                  onClick={() => navigate(PitRouter.GAME_LIST)}
                />

                <LeaderBoardButton
                  className="desktop"
                  selectedGame={selectedGame}
                  gameDetail={gameDetail}
                />
                <GameTitleStyled>
                  {gameInfo ? gameInfo?.name : 'Loading Game'}
                </GameTitleStyled>
                <CloseButton
                  style={{ zIndex: 3 }}
                  onClick={() => navigate(PitRouter.GAME_LIST)}
                />
              </GameDetailContentTitleWrapper>

              {isLoggedIn && player?.isLoggedIn ? (
                tokenLoading ? (
                  <Spin spinning={tokenLoading} />
                ) : !saveOneTimeToken ? (
                  <WarningText>Something went wrong!</WarningText>
                ) : (
                  <GameDetailContentMainWrapper
                    className="responsive-iframe"
                    allow="autoplay; fullscreen; geolocation; microphone; camera; midi"
                    src={`${process.env.REACT_APP_GAME}/${gameInfo?.slug}/${saveActiveVersion}/?token=${saveOneTimeToken}`}
                    id="game_drop"
                    loading="eager"
                  />
                )
              ) : (
                <WarningText>Please log in to play the game!</WarningText>
              )}
            </GameDetailContentWrapper>

            <GameDetailStatisticWrapper>
              {selectedGame && (
                <>
                  <GameDetailStatisticInfo>
                    <LeaderBoardButton
                      className="mobile"
                      selectedGame={selectedGame}
                      gameDetail={gameDetail}
                    />
                    <GameDetailStatisticGameName>
                      {gameInfo ? gameInfo?.name : 'Loading Game'}
                    </GameDetailStatisticGameName>
                    <GameDetailStatisticInfoTags>
                      {gameInfo?.tags?.map((tag, index) => (
                        <GameDetailStatisticInfoTag key={index}>
                          {tag}
                        </GameDetailStatisticInfoTag>
                      ))}
                    </GameDetailStatisticInfoTags>
                  </GameDetailStatisticInfo>
                  <GameDetailStatisticContent>
                    <GameDetailStatisticDescription>
                      {JSON.parse(gameInfo?.description || '""')}
                    </GameDetailStatisticDescription>
                    <GameDetailStatisticItems>
                      <GameDetailStatisticItem>
                        <GameDetailStatisticItemTitle>
                          Total revenue
                        </GameDetailStatisticItemTitle>
                        <GameDetailStatisticItemValue className="revenue">
                          $
                          {Number(selectedGame?.totalRevenue).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                            },
                          ) || 0}
                        </GameDetailStatisticItemValue>
                      </GameDetailStatisticItem>

                      <GameDetailStatisticItem>
                        <GameDetailStatisticItemTitle>
                          Total unique players
                        </GameDetailStatisticItemTitle>
                        <GameDetailStatisticItemValue>
                          {Number(selectedGame?.totalPlayers).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                            },
                          ) || 0}
                        </GameDetailStatisticItemValue>
                      </GameDetailStatisticItem>

                      <GameDetailStatisticItem>
                        <GameDetailStatisticItemTitle>
                          Total attempts
                        </GameDetailStatisticItemTitle>
                        <GameDetailStatisticItemValue>
                          {selectedGame?.totalAttempts
                            ?.toLocaleString('de-DE', {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                            })
                            .replace(/\./g, ' ') || 0}
                        </GameDetailStatisticItemValue>
                      </GameDetailStatisticItem>

                      <GameDetailStatisticItem>
                        <GameDetailStatisticItemTitle>
                          Total biga credits
                        </GameDetailStatisticItemTitle>
                        <GameDetailStatisticItemValue>
                          <img src={bigaCreditIcon} alt="" width={32} />
                          {(
                            parseFloat(
                              formatUnits(
                                selectedGame?.totalOfSpentAmount,
                                selectedGame.tokenInfo.decimals || 0,
                              ),
                            ) || 0
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2,
                          })}
                        </GameDetailStatisticItemValue>
                      </GameDetailStatisticItem>
                    </GameDetailStatisticItems>
                  </GameDetailStatisticContent>
                </>
              )}
            </GameDetailStatisticWrapper>
          </GameDetailWrapper>

          <GameRecommentWrapper>
            <GameRecommentText>You may also like</GameRecommentText>
            <Row gutter={[20, 20]} align="bottom" style={{ width: '100%' }}>
              {recommendedGameList?.map((item, index) => (
                <GameStyledCol
                  xl={6}
                  lg={8}
                  md={12}
                  sm={12}
                  xs={12}
                  key={index}
                >
                  <GameItem {...item} showDetail={() => showDetail(index)} />
                </GameStyledCol>
              ))}
            </Row>
          </GameRecommentWrapper>
        </PlayGameContainer>
        <GameDetailDrawer gameDetail={gameDetail} />
        <CongratsLeaderboardPopup shareTwitterURL={shareTwitterURL} />
        <CongratsLevelMasterPopup shareTwitterURL={shareTwitterURL} />
      </PlayGameWrapper>
    </HomepageTemplate>
  );
};
