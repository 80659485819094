import { PopupButton } from '@typeform/embed-react';
import { Collapse } from 'antd';
import { media } from 'constants/enum/breakpoints';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
`;

export const TextBIGA = styled(Text)`
  color: #0a3885;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-decoration-line: underline;
  cursor: pointer;
  display: inline-block;
`;

export const TextBIGAMail = styled(Link)`
  color: rgba(181, 181, 181, 1);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-decoration-line: underline;
  cursor: pointer;
  display: inline-block;
  font-style: normal;
  font-family: Neue Haas Grotesk Display Pro;

  &:hover,
  &:focus,
  &:active {
    color: rgba(0, 170, 253, 1);
    text-decoration-line: underline;
  }

  &.third-block-email {
    color: rgba(232, 232, 232, 1);
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;

    ${media.sm} {
      font-size: 16px;
      font-weight: 400;
      color: rgba(181, 181, 181, 1);
    }
  }
`;

export const TextMail = styled(TextBIGAMail)`
  font-size: 16px;
`;

export const TextBIGAButton = styled(PopupButton)`
  color: rgba(181, 181, 181, 1);
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  font-style: normal;
  display: inline-block;
  text-decoration-line: underline;
  font-family: Neue Haas Grotesk Display Pro;

  &:hover,
  &:focus,
  &:active {
    color: rgba(0, 170, 253, 1);
    text-decoration-line: underline;
  }

  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
`;

export const SupportWrapper = styled.div`
  padding: 0 90px;
  background-color: rgba(15, 15, 19, 1);
  position: relative;
  overflow-x: hidden;

  ${media.xl2} {
    padding: 0 40px;
  }

  ${media.xl2} {
    padding: 0 20px 0 40px;
  }

  ${media.lg} {
    padding: 20px 20px 0 40px;
  }

  ${media.md} {
    padding: 50px 0 70px 0;
  }
`;

export const SupportContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  position: relative;
  z-index: 2;

  ${media.xl} {
    gap: 10px;
  }

  ${media.lg} {
    flex-direction: column;
    gap: 40px;
  }
`;

export const FirstBlock = styled.div`
  display: flex;
  padding: 70px 0 0;
  flex-direction: column;
  gap: 60px;
  margin-top: 24px;
  width: 396px;
  flex-shrink: 0;
  padding: 75px 20px 0;

  ${media.xl} {
    width: 380px;
  }

  ${media.lg} {
    width: 100%;
    padding: 0 32px;
    margin-top: 0;
    gap: 40px;
  }
`;

export const FirstBlockItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${media.sm} {
    gap: 10px;
  }
`;

export const FirstBlockItemTitle = styled(Text)`
  color: rgba(232, 232, 232, 1);
  font-size: 32px;
  font-weight: 700;
  line-height: 28px;

  ${media.sm} {
    font-size: 24px;
  }
`;

export const FirstBlockItemDecription = styled(Text)`
  color: rgba(181, 181, 181, 1);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;

  ${media.sm} {
    font-size: 16px;
  }
`;

export const SecondBlock = styled.div`
  display: none;
  border: 1px solid rgba(62, 62, 62, 1);

  ${media.lg} {
    display: block;
  }
`;

export const ThirdBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 24px;
  max-width: 100%;
  width: 100%;

  ${media.lg} {
    width: 100%;
    margin-top: 0;
  }
`;

export const ThirdBlockHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 60px;

  ${media.xl} {
    padding: 0;
  }

  ${media.md2} {
    padding: 0 12px;
  }
`;

export const ThirdBlockHeaderDecription = styled(Text)`
  color: rgba(232, 232, 232, 1);
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  align-self: stretch;
  text-align: center;

  ${media.sm} {
    font-size: 16px;
    color: rgba(181, 181, 181, 1);
  }
`;

export const ThirdBlockBody = styled.div`
  padding: 0px 25px;
  &::-webkit-scrollbar {
    display: none;
  }

  & > .ant-collapse-borderless {
    background-color: transparent;
  }

  &
    > .ant-collapse-icon-position-end
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    top: 0;
  }

  &
    > .ant-collapse-icon-position-end
    > .ant-collapse-item
    > .ant-collapse-header {
    padding: 0 50px 0 0;
  }

  .ant-collapse-item {
    background: rgba(21, 21, 27, 1);
    border-radius: 24px;
    border: none;
    margin-bottom: 20px;
    padding: 30px;

    .ant-collapse-expand-icon {
      svg {
        width: 29px;
        height: 29px;
        g path {
          fill: #fff;
        }
      }
    }

    &:last-child {
      border-radius: 24px;
    }
  }

  .ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 20px 0 0;
  }

  ${media.xl} {
    padding: 0;
  }

  ${media.md2} {
    padding: 0 12px;

    .ant-collapse-item {
      margin-bottom: 24px;
    }
  }
`;

export const CollapseWrapper = styled(Collapse)``;

export const CollapseHeader = styled(Text)`
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;

  ${media.sm} {
    font-size: 20px;
  }
`;

export const CollapseContent = styled(Text)`
  color: rgba(232, 232, 232, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  opacity: 0.8;

  a {
    color: rgba(232, 232, 232, 1);
    text-decoration: underline;

    &:hover {
      color: rgba(0, 170, 253, 1);
    }
  }

  u {
    font-weight: 700;
    color: #fff;
  }
`;

export const TitlePageWrapper = styled.div`
  gap: 12px;
  display: flex;
  font-size: 32px;
  font-weight: 700;
  align-items: center;
  color: #fff;
  justify-content: center;

  ${media.xl} {
    justify-content: center;
    font-size: 30px;
    padding-left: 0;
  }

  ${media.sm} {
    font-size: 24px;
  }
`;

export const MobileTitlePageWrapper = styled(TitlePageWrapper)`
  display: none;

  ${media.lg} {
    display: flex;
  }

  ${media.sm} {
    font-size: 32px;
  }
`;

export const SupportIconWrapper = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 187, 255, 0.24);
  border-radius: 50px;
  svg path {
    stroke: #00aafd;
  }
  &.icon-of-question {
    ${media.lg} {
      display: none;
    }
  }
`;

export const AboveGradient = styled.img`
  position: absolute;
  top: 600px;
  left: -200px;
  width: 1700px;
  height: auto;

  ${media.md} {
    display: none;
  }
`;

export const BelowGradient = styled.img`
  position: absolute;
  top: 1200px;
  right: -800px;
  width: 1400px;
  height: auto;

  ${media.md} {
    display: none;
  }
`;

export const MobileGradient = styled.img`
  position: absolute;
  top: 350px;
  right: -250px;
  width: 480px;
  height: auto;
  display: none;

  ${media.md} {
    display: block;
  }
`;
