import { Button, Input } from 'antd';
import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const CashierRightContentSecondBlock = styled(Text)`
  width: 100%;
  color: rgba(240, 240, 240, 1);
  text-align: center;
  font-size: 32px;
  font-weight: 500;
  padding-top: 20px;

  ${media.sm} {
    font-size: 26px;
    padding-top: 0;
    font-weight: 400;
  }
`;

export const CashierRightContentThirdBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  & > .ant-input {
    padding: 0;
  }

  & > .ant-input::-webkit-outer-spin-button,
  .ant-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  & > .ant-input::placeholder {
    color: rgba(169, 169, 169, 1);
    font-family: Neue Haas Grotesk Display Pro;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    opacity: 0.24;
  }

  .ant-input-borderless,
  .ant-input-borderless-disabled,
  .ant-input-borderless-focused,
  .ant-input-borderless:focus,
  .ant-input-borderless:hover,
  .ant-input-borderless[disabled] {
    background-color: rgba(31, 31, 38, 1);
  }

  ${media.sm} {
    padding: 0 10px;
  }
`;

export const ThirdBlockTitle = styled(Text)`
  color: rgba(169, 169, 169, 1);
  font-size: 16px;
  font-weight: 500;

  ${media.sm} {
    font-weight: 400;
  }
`;

export const ThirdBlockValue = styled(Input)`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  background-color: rgba(31, 31, 38, 1);
  max-width: 375px;
  border-radius: 8px;
  height: 76px;

  .ant-input-affix-wrapper,
  .ant-input {
    text-align: right;

    border: none;
    outline: none;
    box-shadow: none;
    background-color: rgba(31, 31, 38, 1);
    padding: 0;
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    font-family: Neue Haas Grotesk Display Pro;

    &:focus,
    &:hover,
    &.ant-input-affix-wrapper:focus,
    &.ant-input-affix-wrapper:hover {
      border: none;
      outline: none;
      box-shadow: none;
      background-color: rgba(31, 31, 38, 1);
    }
    &::placeholder {
      color: rgba(169, 169, 169, 1);
      opacity: 0.24;
      background-color: rgba(31, 31, 38, 1);
    }
    &:-ms-input-placeholder {
      color: rgba(169, 169, 169, 1);
      opacity: 0.24;
    }
    &::-ms-input-placeholder {
      color: rgba(169, 169, 169, 1);
      opacity: 0.24;
    }
    .anticon.anticon-close-circle {
      color: rgba(169, 169, 169, 1);
      opacity: 0.24;
    }
  }

  .ant-input.ant-input-borderless {
    padding-right: 16px;
  }

  .ant-input-group-addon {
    width: 200px;
    padding: 0;
    color: rgba(169, 169, 169, 1);
    border: none;
    font-size: 35px;
    text-align: left;
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    background-color: transparent;
    font-family: Neue Haas Grotesk Display Pro;
  }
`;

export const ThirdBlockUnit = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: #fff;
`;

export const ThirdBlockErrorText = styled(ThirdBlockUnit)`
  color: #ff3b3b;
`;

export const CashierRightContentFourthBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  padding: 0 94px;

  ${media.xl1} {
    padding: 0 40px;
  }

  ${media.sm} {
    padding: 0 12px;
  }
`;

export const FourthBlockContentWrapper = styled.div<{
  withdraw?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => (props.withdraw ? '16px' : '24px')};
  width: 100%;
`;

export const FourthBlockSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const FourthBlockSelectTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  padding-top: 30px;

  ${media.xl1} {
    padding-top: 10px;
  }

  ${media.sm} {
    padding-top: 0;
  }
`;

export const FourthBlockSelectButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  padding: 10px 0 30px;

  ${media.xl1} {
    padding: 10px 0 10px;
  }

  ${media.xl} {
    flex-wrap: wrap;
    justify-content: center;
  }

  ${media.sm} {
    padding: 0 0 10px;
  }
`;

export const FourthBlockSelectButton = styled.div<{ disabled?: boolean }>`
  background-color: rgba(35, 35, 45, 1);
  padding: 0 19px;
  border-radius: 11px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover,
  &:active,
  &:focus,
  &.number-active {
    background: linear-gradient(rgba(1, 140, 254, 0), rgba(1, 84, 152, 1));
    border: 2px solid rgba(166, 181, 194, 1);
  }
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  ${media.xl} {
    flex: 1 1 calc(33.33% - 10px);
  }

  ${media.sm} {
    height: 55px;
  }
`;

export const FourthBlockSelectButtonText = styled(Text)`
  cursor: pointer;
  user-select: none;

  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  font-weight: 500;
`;

export const Line = styled.div`
  flex: 1;
  height: 1px;
  background: rgba(65, 65, 65, 1);
`;

export const Title = styled(Text)`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: rgba(169, 169, 169, 1);
  margin: 0 30px;

  ${media.sm} {
    margin: 0;
  }
`;

export const FourthBlockBalanceWrapper = styled.div`
  padding: 5px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border: 1px solid rgba(31, 31, 38, 1);
  border-radius: 12px;

  ${media.sm} {
    flex-direction: column-reverse;
    border: none;
    gap: 10px;
  }
`;

export const FourthBlockBalanceUnit = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f7;
  cursor: pointer;
  user-select: none;
  padding: 8px 8px 8px 16px;
  width: 170px;
  height: 49px;
  border-radius: 10px;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  ${media.xl} {
    width: 130px;
  }

  ${media.lg} {
    width: 170px;
  }
`;

export const FourthBlockBalanceUnitText = styled.div`
  text-align: center;
  color: #000;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const FourthBlockButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
`;

export const FourthBlockButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  width: 100%;
  border: none;
  height: auto;
  background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
    0px 2px 4px -1px rgba(10, 70, 82, 0.12),
    0px 16px 24px 0px rgba(0, 187, 255, 0.24),
    0px 8px 8px -4px rgba(0, 187, 255, 0.12);
  border-radius: 12px;
  color: #fff;
  font-weight: 700;
  font-size: 18px;

  padding: 16px 0;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #00aafd;
    background: #fff;
    outline: 0;
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    border: none;
    outline: 0;
  }

  ${media.sm} {
    height: 54px;
    width: 90%;
  }
`;

export const FourthBlockButtonGemini = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(11, 56, 133, 0.24);
`;

export const Gemini = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const FourthBlockButtonTextGemini = styled.div<{ disabled?: boolean }>`
  padding: 16px;
  user-select: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 100%;
  opacity: ${props => (props.disabled ? '0.4' : '1')};

  text-align: center;
  color: #000;
  text-align: center;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const FourthBlockButtonPayWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  /* align-self: stretch; */
  width: 100%;

  & > .ant-tooltip-disabled-compatible-wrapper {
    flex: 1;
  }

  ${media.sm} {
    gap: 12px;
    flex-direction: column;
  }
`;

export const FourthBlockButtonPay = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(21, 21, 27, 1);
  outline: 0;
  width: 100%;
  border: 1px solid rgba(121, 121, 121, 0.28);
  height: auto;
  border-radius: 12px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  height: 69px;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    background: #fff;
    outline: 0;
    border: 1px solid rgba(11, 56, 133, 0.24);
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #fff;
    border: 1px solid rgba(11, 56, 133, 0.24);
    background: #fff;
    outline: 0;
  }
  &.ant-btn[disabled],
  &.ant-btn[disabled]:active,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:hover {
    opacity: 0.2;
  }
`;

export const LinePay = styled.div`
  height: 1px;
  flex: 1;
  background: rgba(65, 65, 65, 1);
`;

export const TitlePay = styled(Text)`
  color: rgba(169, 169, 169, 1);
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin: 0 30px;

  ${media.sm} {
    margin: 0;
  }
`;
