import { ExclamationCircleTwoTone } from '@ant-design/icons';
import closeicon from 'assets/vectors/logo-profile-close.svg';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';

import {
  CloseIcon,
  CloseWrapper,
  ErrorDetailText,
  ErrorText,
  ModalPaymentErrorContainer,
  ModalPaymentErrorWrapper,
  ModalWrapper,
} from './styled';

export const PaymentErrorModal = () => {
  const open = useModalIsOpen(ApplicationModal.PAYMENT_CHECKOUT_ERROR);
  const toggleModal = useToggleModal(ApplicationModal.PAYMENT_CHECKOUT_ERROR);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleCancel = () => {
    toggleModal();
    navigate(pathname);
  };

  return (
    <ModalWrapper isOpen={open} onCancel={handleCancel} width="448px">
      <ModalPaymentErrorWrapper>
        <ModalPaymentErrorContainer>
          <ExclamationCircleTwoTone
            twoToneColor="#ff1900"
            className="payment-checkout-icon"
          />
          <ErrorText>Payment Error!</ErrorText>
          <ErrorDetailText>
            Unfortunately there was an error processing your payment. Please try
            again or contact us for assistance.
          </ErrorDetailText>
        </ModalPaymentErrorContainer>
      </ModalPaymentErrorWrapper>
    </ModalWrapper>
  );
};
