import { StockOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { ReactComponent as LogoAffiliate } from 'assets/vectors/logo-navbar-affiliate.svg';
import { ReactComponent as LogoCashier } from 'assets/vectors/logo-navbar-cashier.svg';
import { ReactComponent as LogoGameManagement } from 'assets/vectors/logo-navbar-game-management.svg';
import { ReactComponent as LogoGames } from 'assets/vectors/logo-navbar-games.svg';
import { ReactComponent as LogoHelp } from 'assets/vectors/logo-navbar-help.svg';
import { ReactComponent as LogoLeaderboard } from 'assets/vectors/logo-navbar-leaderboard.svg';
import { ReactComponent as LogoStaking } from 'assets/vectors/logo-navbar-staking.svg';
import { ReactComponent as LogoSetting } from 'assets/vectors/logo-navbar-setting.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right-icon.svg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export interface DefaultTheme {
  collapsed: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div<{ theme: DefaultTheme }>`
  color: #000;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
  display: ${props => props.theme.collapsed && 'none'};
  @media (max-width: 1440px) {
    display: none;
  }
`;

export const NavbarWrapper = styled(Wrapper)<{ theme: DefaultTheme }>`
  position: fixed;
  height: 97vh;
  transition: all 0.3s;
  z-index: 999;

  .navbar-collapse-icon {
    display: none;
  }

  :hover,
  :focus {
    .navbar-collapse-icon {
      display: block;
    }
  }
`;

export const LogoWrapper = styled(Wrapper)<{ theme: DefaultTheme }>`
  padding: 15px 0 36px 39px;
`;

export const Logo = styled.img<{ theme: DefaultTheme }>`
  width: 106px;
  height: 43px;
  cursor: pointer;
`;

export const Line = styled.span<{ theme: DefaultTheme }>`
  width: 24px;
  height: 1px;
  opacity: 0.1;
  align-self: left;
  background: #e4e4e4;
  margin-bottom: 25px;
  margin-left: 36px;
  flex-shrink: 0;
`;

export const MainWrapper = styled(Wrapper)`
  position: relative;
  flex: 1;
  background: #15151b;
  border-radius: 14px;
  margin-left: 42px;
  width: 95px;
  height: calc(100% - 85px);

  &.expanded {
    width: 220px;
  }
`;

export const MenuWrapper = styled.div<{ theme: DefaultTheme }>`
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: auto;

  ${Line}:last-child {
    display: none;
  }

  &::-webkit-scrollbar {
    width: 1px;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track-piece {
    background: rgba(86, 86, 86, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 1);
  }
`;

export const ArrowRightIcon = styled(ArrowRight)`
  position: absolute;
  top: 45px;
  right: 5px;
  cursor: pointer;

  &.expanded {
    right: 10px;
    rotate: 180deg;
  }

  &:hover path {
    fill: #00aafd;
  }
`;

export const MenuLogoGames = styled(LogoGames)`
  width: 28px;
  height: 28px;

  &:hover path {
    fill: rgba(255, 29, 0, 1);
  }
`;

export const MenuLogoStaking = styled(LogoStaking)`
  width: 28px;
  height: 28px;

  &:hover path {
    fill: rgba(157, 224, 113, 1);
  }
`;

export const MenuLogoLeaderboard = styled(LogoLeaderboard)`
  width: 28px;
  height: 28px;

  &:hover path {
    fill: rgba(255, 195, 0, 1);
  }
`;
export const MenuLogoCashier = styled(LogoCashier)`
  width: 28px;
  height: 28px;

  &:hover path {
    fill: rgba(70, 76, 253, 1);
  }
`;
export const MenuLogoGameManagement = styled(LogoGameManagement)`
  width: 25px;
  height: 25px;

  &:hover {
    stroke: rgba(255, 29, 0, 1);
    path,
    polygon {
      fill: rgba(255, 29, 0, 1);
    }
  }
`;
export const MenuLogoAdminPanel = styled(UserOutlined)`
  font-size: ${props => (props.theme.collapsed ? '28px' : '20px')};
  color: #fff;

  &:hover {
    color: rgba(70, 76, 253, 1);
  }
`;

export const MenuLogoSetting = styled(LogoSetting)`
  width: 24px;
  height: 24px;

  > g {
    opacity: 1;
  }

  &:hover g g g {
    path,
    circle {
      stroke: rgba(255, 29, 0, 1);
    }
  }

  @media (max-height: 830px) {
    margin-left: -1px;
  }
`;

export const MenuLogoAffiliate = styled(LogoAffiliate)`
  font-size: ${props => (props.theme.collapsed ? '28px' : '24px')};

  &:hover path {
    fill: rgba(157, 224, 113, 1);
  }
`;

export const MenuLogoAdminStats = styled(StockOutlined)`
  font-size: ${props => (props.theme.collapsed ? '28px' : '24px')};
  color: #fff;

  &:hover {
    color: rgba(255, 195, 0, 1);
  }
`;

export const SupportLogo = styled(LogoHelp)<{ theme: DefaultTheme }>`
  width: 23px;
  height: 23px;

  > g {
    opacity: 1;
  }

  &:hover g path {
    stroke: rgba(255, 195, 0, 1);
  }

  @media (max-height: 830px) {
    margin-top: 50px;
    margin-left: -1px;
    margin-bottom: 30px;
  }
`;

export const MenuText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
`;

export const MenuItemWrapper = styled(Link)`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  align-self: ${props => props.theme.collapsed && 'stretch'};
  justify-content: ${props => props.theme.collapsed && 'center'};
  gap: 8px;
  cursor: pointer;
  align-self: stretch;
  justify-content: left;
  margin-bottom: 25px;
  height: 28px;

  &:focus,
  &.navbar-active {
    .logo-navbar {
      fill: #00aafd;
    }

    .logo-navbar-stroke {
      stroke: #0a3885;
    }

    ${MenuText}, ${MenuLogoAdminStats}, ${MenuLogoAdminPanel} {
      color: #00aafd;
    }

    ${MenuLogoAffiliate} {
      path {
        fill: #00aafd;
      }
    }

    svg g {
      path {
        stroke: #00aafd;
      }
      circle {
        stroke: #00aafd;
      }
    }

    span {
      color: #00aafd;
    }
  }

  &:hover {
    .games {
      color: rgba(255, 29, 0, 1);
    }
    ${MenuLogoGames} {
      path {
        fill: rgba(255, 29, 0, 1);
      }
    }

    .leaderboard {
      color: rgba(255, 195, 0, 1);
    }
    ${MenuLogoLeaderboard} {
      path {
        fill: rgba(255, 195, 0, 1);
      }
    }

    .affiliate {
      color: rgba(157, 224, 113, 1);
    }
    ${MenuLogoAffiliate} {
      path {
        fill: rgba(157, 224, 113, 1);
      }
    }

    .cashier {
      color: rgba(70, 76, 253, 1);
    }
    ${MenuLogoCashier} {
      path {
        fill: rgba(70, 76, 253, 1);
      }
    }
    .game-management {
      color: rgba(255, 29, 0, 1);
    }
    ${MenuLogoGameManagement} {
      stroke: rgba(255, 29, 0, 1);
      path,
      polygon {
        fill: rgba(255, 29, 0, 1);
      }
    }

    .admin-stats {
      color: rgba(255, 195, 0, 1);
    }
    ${MenuLogoAdminStats} {
      color: rgba(255, 195, 0, 1);
    }

    .admin-panel {
      color: rgba(70, 76, 253, 1);
    }
    ${MenuLogoAdminPanel} {
      color: rgba(70, 76, 253, 1);
    }

    .staking {
      color: rgba(157, 224, 113, 1);
    }
    ${MenuLogoStaking} {
      path {
        fill: rgba(157, 224, 113, 1);
      }
    }

    .FAQs {
      color: rgba(255, 195, 0, 1);
    }

    ${SupportLogo} {
      g path {
        stroke: rgba(255, 195, 0, 1);
      }
    }
  }
`;

export const NameItemWrapper = styled.span`
  display: none;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  flex: 1;
  padding-top: 2px;
  margin-left: -40px;
  &.navbar-active {
    color: rgba(0, 170, 253, 1);
  }

  &.expanded {
    display: flex;
  }

  @media (max-height: 830px) {
    &.FAQs {
      margin-top: 50px;
      margin-bottom: 30px;
    }
  }
`;

export const IconItemWrapper = styled.div`
  width: 100px;
  display: flex;
  justify-content: center;
`;

export const Username = styled.span`
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
`;

export const ProfileWrapper = styled(Link)<{ theme: DefaultTheme }>`
  display: flex;
  width: 100%;
  padding: 22px 0 31px 0;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  &:hover {
    ${Username} {
      color: rgba(255, 195, 0, 1);
    }
  }

  &.navbar-active {
    ${Username} {
      color: #00aafd;
    }

    &:hover {
      ${Username} {
        color: rgba(255, 195, 0, 1);
      }
    }
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
`;

export const UsernameWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -40px;
  padding: 0 20px;
  word-break: break-word;
  text-align: center;
`;

export const ProfileMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  width: 100px;
`;

export const ProfileAvatarWrapper = styled.div`
  display: flex;
  padding: 3px;
  align-items: flex-start;
  gap: 10.909px;
  border-radius: 10px;
  border: 1px solid #404040;
  width: 60px;
  height: 60px;
`;

export const ProfileAvatar = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 12px;
`;

export const ProfileText = styled(Text)`
  font-size: 18px;
  font-weight: 600;
`;

export const SupportText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
`;

export const SupportWrapper = styled(Link)<{ theme: DefaultTheme }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  justify-content: ${props => props.theme.collapsed && 'center'};

  cursor: pointer;
  user-select: none;
  &:hover,
  &:focus,
  &.navbar-active {
    .logo-navbar {
      fill: #00aafd;
    }

    .logo-navbar-stroke {
      stroke: #0a3885;
    }

    ${SupportText} {
      color: #0a3885;
    }
  }
`;

export const DropdownWrapper = styled(Menu)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem 0;
  background: #15151b;
  border-radius: 10px;
  width: 180px;
  color: #fff;
  font-weight: 500;
  box-shadow: 0px 8px 20px 4px rgba(255, 255, 255, 0.1);
`;

export const DropdownItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  user-select: none;

  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;

  &:hover {
    background: rgba(255, 255, 255, 0.03);
  }
`;

export const CollapseButton = styled.div`
  position: absolute;
  right: -6px;
  top: 80px;

  & > .navbar-collapse-icon {
    scale: 1.6;
    color: #000;
  }

  @media (max-width: 1440px) {
    display: none;
  }
`;
