import biga from 'assets/vectors/BIGA.svg';
import { ReactComponent as Discord } from 'assets/icons/discord.svg';
import { ReactComponent as Instagram } from 'assets/icons/instagram.svg';
import { ReactComponent as Mail } from 'assets/icons/mail.svg';
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg';
import gradientColor from 'assets/main/play-earn/gradient-color.gif';
import ReactGA from 'react-ga4';
import { PitRouter } from 'constants/routers';
import { Link, useNavigate } from 'react-router-dom';
import { MainButton } from 'components/Button/MainButton';
import { GaActions, GaCategories, GaLabels } from 'constants/enum/GaEvent';
import { GrayButton } from 'components/Button/GrayButton';

import {
  BigaTitle,
  Description,
  ContentWrapper,
  JackpotAmount,
  JackpotTitle,
  JackpotWrapper,
  PlayScoreEarnWrapper,
  TopWrapper,
  SocialWrapper,
  SocialText,
  SocialIcon,
  BottomWrapper,
  RevenueValue,
  PlayersValue,
  PlayersWrapper,
  RevenueWrapper,
  HorizontalImage,
} from './styled';
import { PlayEarnAnimation } from './PlayEarnAnimation';
import { useGetTotalArcadeRevenue } from 'hooks/landingpage/useGetTotalArcadeRevenue';
import { useGetTotalUniquePlayers } from 'hooks/landingpage/useGetTotalUniquePlayers';
import { formatUnits } from 'ethers/lib/utils';
import { useMemo } from 'react';

export const PlayScoreEarn = () => {
  const navigate = useNavigate();
  const { totalArcadeRevenue } = useGetTotalArcadeRevenue();
  const { totalUniquePlayers } = useGetTotalUniquePlayers();

  const handleRouterPublicAlphaButton = () => {
    ReactGA.event({
      category: GaCategories.GENERAL,
      action: GaActions.ACTION_PUBLIC_ALPHA,
      label: GaLabels.LABEL_PUBLIC_ALPHA,
    });
    navigate(PitRouter.REGISTER);
  };

  const isOnMobileDevice = () => {
    return /Android|Mobi|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent,
    );
  };

  const onPlayNow = () => {
    const telegramLink = process.env.REACT_APP_TELEGRAM_LINK;
    if (!!telegramLink && isOnMobileDevice())
      window.location.href = process.env.REACT_APP_TELEGRAM_LINK;
    else handleRouterPublicAlphaButton();
  };

  const onScrollToHowToWork = () => {
    document.getElementById('how-to-work').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const totalRevenueValue = useMemo(() => {
    if (totalArcadeRevenue && totalArcadeRevenue.totalEntryFee)
      return (
        parseFloat(
          formatUnits(
            totalArcadeRevenue?.totalEntryFee,
            totalArcadeRevenue?.decimals || 0,
          ),
        ) * parseFloat(totalArcadeRevenue?.tokenPrice || '0')
      );
    return 0;
  }, [totalArcadeRevenue]);

  return (
    <PlayScoreEarnWrapper>
      <TopWrapper>
        <img src={biga} alt="" />
        <JackpotWrapper onClick={onPlayNow}>
          <JackpotTitle>Enter Arcade</JackpotTitle>
          {/* <JackpotAmount>
            $
            {((1 / 40) * totalRevenueValue).toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          </JackpotAmount> */}
        </JackpotWrapper>
      </TopWrapper>
      <ContentWrapper>
        <div className="content d-flex flex-column align-items-center">
          <BigaTitle>
            Play,
            <br /> Win & <br />
            Own{' '}
          </BigaTitle>
          <Description>
            The more levels you own the more money you make.
          </Description>
          <MainButton onClick={onPlayNow}>Play now</MainButton>
          <GrayButton onClick={onScrollToHowToWork} className="mt-3">
            How BIGA Works
          </GrayButton>
        </div>
        <div className="d-flex flex-column align-items-center">
          <PlayEarnAnimation />
        </div>
      </ContentWrapper>
      <BottomWrapper>
        <PlayersWrapper>
          <div>TOTAL PLAYERS</div>
          <PlayersValue className="font-xl">
            {totalUniquePlayers?.toLocaleString() || 0}
          </PlayersValue>
        </PlayersWrapper>
        <RevenueWrapper>
          <div>TOTAL REVENUE</div>
          <RevenueValue>
            $
            {totalRevenueValue.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          </RevenueValue>
        </RevenueWrapper>
        {/* <img src={gradientColor} alt="" /> */}
      </BottomWrapper>
      <HorizontalImage src={gradientColor} alt="" />

      <SocialWrapper>
        <SocialText>Join us on</SocialText>
        <Link to="https://twitter.com/bigaarcade" target="_blank">
          <Twitter className="twitter" />
        </Link>
        <Link to="https://www.instagram.com/bigaarcade/" target="_blank">
          <Instagram className="instagram" />
        </Link>
        <Link to="https://discord.gg/nGcGdS7tT3" target="_blank">
          <Discord className="discord" />
        </Link>
        <Link to="mailto:hello@bigarcade.org" target="_blank">
          <Mail className="mail" />
        </Link>
      </SocialWrapper>
    </PlayScoreEarnWrapper>
  );
};
