import { Divider } from 'antd';
import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const CashierWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 142px 120px;
  position: relative;
  overflow: hidden;
  min-height: 100vh;

  ${media.xl2} {
    padding: 20px 142px 120px 100px;
  }

  ${media.xl} {
    padding: 20px 100px 120px 100px;
  }

  ${media.xl} {
    padding: 20px 90px 120px 80px;
  }

  ${media.md} {
    padding: 20px 70px 120px 70px;
  }

  ${media.sm} {
    padding: 20px 12px 100px;
  }
`;

export const CashierMainWrapper = styled.div`
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: left;

  ${media.lg} {
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    row-gap: 30px;
  }
`;

export const CashierLeftContent = styled.div`
  width: 400px;
  gap: 12px;
  display: flex;
  flex-direction: column;

  ${media.xl1} {
    width: 320px;
  }

  ${media.lg} {
    width: 100%;
  }

  ${media.sm} {
    gap: 0;
    width: 100%;
    padding: 0 18px;
  }
`;

export const TitlePageWrapper = styled.div`
  gap: 12px;
  display: flex;
  font-size: 32px;
  font-weight: 700;
  align-items: center;
  color: #fff;

  &.mobile {
    display: none;
  }

  ${media.lg} {
    &.desktop {
      display: none;
    }

    &.mobile {
      display: flex;
    }
  }
`;

export const WalletIconWrapper = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 187, 255, 0.24);
  border-radius: 50px;
  svg path {
    fill: #00aafd;
  }
`;

export const LeftContentHeader = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: #a9a9a9;
`;

export const LeftContentBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 39px;

  ${media.xl1} {
    width: 250px;
    margin-top: 20px;
    gap: 30px;
  }

  ${media.lg} {
    width: 100%;
    gap: 35px;
  }
`;

export const LeftContentBodyItem = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:last-child {
    border-bottom: none;
  }
`;

export const LeftContentBodyItemNumberWrapper = styled.div`
  gap: 10px;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #15151b;
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

export const LeftContentBodyItemContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const LeftContentBodyItemTitle = styled(Text)`
  font-size: 20px;
  font-weight: 700;
`;

export const LeftContentBodyItemContent = styled(Text)`
  max-width: 300px;
  font-size: 16px;
  font-weight: 500;
  color: #a9a9a9;
  line-height: 28px;

  ${media.lg} {
    max-width: 100%;
  }
`;

export const CashierRightContent = styled.div`
  max-width: 881px;
  flex: 1;
  gap: 21px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgba(21, 21, 27, 1);
  padding: 0px 0 40px;
  border-radius: 24px;

  ${media.xl1} {
    gap: 10px;
  }

  ${media.lg} {
    max-width: 100%;
    width: 100%;
  }

  ${media.sm} {
    padding: 0px 0 20px;
  }
`;

export const CashierRightContentFirstBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.08);
  }

  &:first-child::after {
    width: 100%;
  }
`;

export const FirstBlockButtonWrapper = styled.div<{
  active?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  border-bottom: ${props =>
    props.active ? '1px solid rgba(0, 170, 253, 1)' : 'none'};
`;

export const FirstBlockButton = styled(Text)<{
  active?: boolean;
}>`
  padding: 20px 58.5px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  font-size: 22px;
  font-weight: 500;

  color: ${props =>
    props.active ? 'rgba(0, 170, 253, 1)' : 'rgba(79, 79, 79, 1)'};

  ${media.sm} {
    font-size: 16px;
    font-weight: 400;
    padding: 14px 0;
  }
`;

export const GradientBottom = styled.img`
  position: absolute;
  bottom: -400px;
  right: -550px;
  pointer-events: none;
  width: 1200px;

  ${media.sm} {
    display: none;
  }
`;

export const StyledDivider = styled(Divider)`
  border-color: rgba(45, 45, 45, 1);
  margin: 0;
  height: 67px;
  top: 0;

  ${media.sm} {
    height: 50px;
  }
`;
