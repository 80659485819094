import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { Tooltip } from 'antd';
import geminiIcon from 'assets/vectors/login-gemini.svg';
import applepayIcon from 'assets/images/withdraw/logo-cashier-applepay.png';
import paypalIcon from 'assets/images/withdraw/logo-cashier-paypal.png';
import stripeIcon from 'assets/images/withdraw/logo-cashier-stripe.png';
import { CHAIN_NETWORK } from 'constants/chains';
import { EventType } from 'constants/enum/EventType';
import { NULL_ADDRESS } from 'constants/value';
import { BigNumber, ethers } from 'ethers';
import { formatEther, formatUnits } from 'ethers/lib/utils';
import { useStoshiCallback } from 'hooks/stoshi/useStoshiCallback';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { refreshNewToken } from 'redux/slices/refreshToken';
import { useAppDispatch } from 'redux/store';
import { postContractAction } from 'services/api/contract_action';
import { LocalhostStorage } from 'utils/sessionStorage';

import {
  FirstBlock,
  FirstBlockTitle,
  FirstBlockValue,
  Line,
  SecondBlock,
  SecondBlockItem,
  SecondBlockItemTitle,
  SecondBlockItemValue,
  SecondBlockItemWrapper,
  SecondBlockTotalTitle,
  SecondBlockTotalValue,
  SecondBlockTotalWrapper,
  StyledTooltip,
  ThirdBlock,
  ThirdBlockButtonBackWrapper,
  ThirdBlockButtonConfirmWrapper,
  ThirdBlockButtonGemini,
  ThirdBlockButtonGeminiWrapper,
  ThirdBlockButtonPay,
  ThirdBlockButtonPayWrapper,
  ThirdBlockButtonWrapper,
  ThirdBlockSelectTitle,
  Title,
} from './styled';
import { ContractName } from 'constants/enum/ContractName';
import { useToggleModal } from 'redux/slices/application/hook';
import { ApplicationModal } from 'redux/slices/application';
import { CryptoDepositConfirmation } from 'components/Modals/WithdrawalConfirmation/CryptoDepositConfirmation';

interface IProps {
  onBack: () => void;
  value: number;
  confirmData: any;
  convertData: any;
  setDisabled: (value: boolean) => void;
}

export const ConfirmPurchase = ({
  onBack,
  value,
  confirmData,
  convertData,
  setDisabled,
}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [gasPriceValue, setGasPriceValue] = useState<BigNumber>();
  const [gasLimitValue, setGasLimitValue] = useState<BigNumber>();
  const { deposit, getGasLimitForDeposit } = useStoshiCallback();
  const dispatch = useAppDispatch();
  const toggleCryptoDepositConfirmationModal = useToggleModal(
    ApplicationModal.CRYPTO_DEPOSIT_CONFIRMATION,
  );

  const { library } = useWeb3React<Web3Provider>();
  const CHAINID = LocalhostStorage.get('chainId');

  const networkInfo = CHAIN_NETWORK[+CHAINID];
  const DEFAULT_CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

  useEffect(() => {
    const getGasPrice = async () => {
      try {
        const gasPrice = await library.getGasPrice();
        setGasPriceValue(gasPrice);
      } catch (error) {
        console.error('Error getting gas price:', error);
      }
    };
    getGasPrice();

    const getGasLimit = async () => {
      try {
        const config = {
          _tokenIn: confirmData?.address,
          _tokenOut:
            +DEFAULT_CHAIN_ID === CHAINID
              ? process.env.REACT_APP_DEFAULT_TOKEN_ADDRESS
              : NULL_ADDRESS,
          _amountIn: convertData,
        };
        const res = await getGasLimitForDeposit(config);
        setGasLimitValue(res);
      } catch (error) {
        console.error('Error getting gas limit:', error);
      }
    };
    getGasLimit();
  }, [confirmData?.address, convertData]);

  const networkFee = useMemo(() => {
    if (gasPriceValue && gasLimitValue) {
      return formatEther(gasPriceValue.mul(gasLimitValue));
    }
    return formatEther(0);
  }, [gasPriceValue, gasLimitValue]);

  const handleBackClick = () => {
    onBack();
  };

  const convertValue = Number(
    formatUnits(value ? convertData : '0', confirmData?.decimals || 18),
  );

  const handlePurchase = async () => {
    try {
      setLoading(true);
      setDisabled(true);
      const config = {
        _tokenIn: confirmData?.address,
        _tokenOut:
          +DEFAULT_CHAIN_ID === CHAINID
            ? process.env.REACT_APP_DEFAULT_TOKEN_ADDRESS
            : NULL_ADDRESS,

        _amountIn: convertData,
      };
      const res = await deposit(config);
      if (res) {
        toggleCryptoDepositConfirmationModal();
        await postContractAction({
          eventName: EventType.Deposited,
          transactionHash: res.transactionHash,
          contractName: ContractName.BIGA_CONTRACT,
          chainId: CHAINID,
        });
        dispatch(refreshNewToken());
        handleBackClick();
      }
    } catch (e) {
      toast.error(e.reason);
      console.log(e);
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  return (
    <>
      <FirstBlock>
        <FirstBlockTitle>Purchase Details</FirstBlockTitle>
        <FirstBlockValue>{value} BIGA</FirstBlockValue>
      </FirstBlock>
      <SecondBlock>
        <SecondBlockItemWrapper>
          <SecondBlockItem>
            <SecondBlockItemTitle>{value} BIGA</SecondBlockItemTitle>
            <SecondBlockItemValue>
              ${confirmData && convertValue + ' ' + confirmData?.symbol}
            </SecondBlockItemValue>
          </SecondBlockItem>
          <SecondBlockItem>
            <SecondBlockItemTitle>Estimated network fees</SecondBlockItemTitle>
            <SecondBlockItemValue>
              0.0005 {networkInfo?.symbol}
            </SecondBlockItemValue>
          </SecondBlockItem>
        </SecondBlockItemWrapper>
        <SecondBlockTotalWrapper>
          <SecondBlockTotalTitle>Total</SecondBlockTotalTitle>
          <SecondBlockTotalValue>
            <div>
              ${confirmData && convertValue + ' ' + confirmData?.symbol}
            </div>
            <div>0.0005 {networkInfo?.symbol}</div>
          </SecondBlockTotalValue>
        </SecondBlockTotalWrapper>
      </SecondBlock>
      <ThirdBlock>
        <ThirdBlockButtonWrapper>
          <ThirdBlockButtonBackWrapper
            onClick={handleBackClick}
            disabled={loading}
          >
            Back
          </ThirdBlockButtonBackWrapper>

          <ThirdBlockButtonConfirmWrapper
            onClick={() => handlePurchase()}
            loading={loading}
          >
            Confirm
          </ThirdBlockButtonConfirmWrapper>
        </ThirdBlockButtonWrapper>

        <ThirdBlockSelectTitle>
          <Line />
          <Title>or</Title>
          <Line />
        </ThirdBlockSelectTitle>

        <ThirdBlockButtonPayWrapper>
          <StyledTooltip title="Coming soon">
            <ThirdBlockButtonPay disabled>
              <img src={stripeIcon} />
            </ThirdBlockButtonPay>
          </StyledTooltip>
          <StyledTooltip title="Coming soon">
            <ThirdBlockButtonPay disabled>
              <img src={paypalIcon} />
            </ThirdBlockButtonPay>
          </StyledTooltip>
          <StyledTooltip title="Coming soon">
            <ThirdBlockButtonPay disabled>
              <img src={applepayIcon} />
            </ThirdBlockButtonPay>
          </StyledTooltip>
        </ThirdBlockButtonPayWrapper>

        {/* <Tooltip title="Coming soon">
          <ThirdBlockButtonGeminiWrapper disabled>
            <img src={geminiIcon} />
            <ThirdBlockButtonGemini>
              Purchase with Gemini
            </ThirdBlockButtonGemini>
          </ThirdBlockButtonGeminiWrapper>
        </Tooltip> */}
      </ThirdBlock>
      <CryptoDepositConfirmation />
    </>
  );
};
