import MainBackground from 'assets/main/play-earn/main-bg.png';
import MainBackgroundMobile from 'assets/main/play-earn/main-bg-mobile.png';
import CarImg from 'assets/main/play-earn/car-image.png';
import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

export const PlayScoreEarnWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url(${MainBackground}) no-repeat center center;
  background-size: cover;
  position: relative;

  ${media.xl} {
    min-height: 900px;
  }

  ${media.md2} {
    min-height: 930px;
    justify-content: unset;
    background: none;
    row-gap: 0px;
  }
`;

export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 40px;
  padding-bottom: 20px;

  ${media.md2} {
    padding: 10px 20px 20px;
    background: url(${MainBackgroundMobile}) no-repeat;
    background-size: 100% 100%;
    img {
      width: 72px;
    }
  }
`;

export const JackpotWrapper = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 186px;
  height: 69px;
  background: linear-gradient(
    90deg,
    rgba(255, 246, 0, 1),
    rgba(253, 129, 0, 1)
  );
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0 6px 12px 0 rgba(255, 115, 0, 0.24);

  &:hover {
    background: linear-gradient(
      90deg,
      rgba(255, 249, 89, 1),
      rgba(253, 129, 0, 1)
    );
    box-shadow: 0 8px 8px -4px rgba(255, 115, 0, 0.12),
      0 16px 24px 0 rgba(255, 115, 0, 0.24);
  }

  ${media.md2} {
    width: 127px;
    height: 40px;
  }
`;

export const JackpotTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
  /* max-width: 100px; */

  ${media.md2} {
    font-weight: 700;
    font-size: 14px;
  }
`;

export const JackpotAmount = styled.div`
  font-size: 20px;
  font-weight: 700;
  text-align: end;

  ${media.md2} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const MainBlockTopLandingPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 24px;
  position: relative;
  height: 100%;
`;

export const BigaTitle = styled.div`
  color: var(--main-white);
  text-align: center;
  font-size: 90px;
  font-weight: 500;
  text-transform: uppercase;
  max-width: 558px;
  line-height: 100%;

  ${media.md2} {
    font-size: 70px;
  }

  ${media.md} {
    font-size: 38px;
  }
`;

export const Description = styled.div`
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  max-width: 390px;
  text-align: center;
  margin: 45px 0;

  ${media.md2} {
    margin: 20px 0;
    font-size: 16px;
    width: 514px;
  }
`;

export const FooterDescription = styled.div`
  color: #fff;
  font-family: 'Changeling Neo';
  font-size: 1.5rem;
  font-weight: 700;
`;

export const LandingScrollDownWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const LandingScrollDownText = styled.div`
  color: #fff;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 0.875rem;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  max-width: 80%;
  width: max-content;
  margin: auto auto;
  z-index: 2;
  gap: 20px;
  padding-bottom: 155px;

  .content {
    z-index: 3;
  }

  ${media.xl2} {
    zoom: 0.9;
  }

  ${media.xl1} {
    max-width: 100%;
    padding-right: 20px;
    zoom: 0.8;
  }

  ${media.xl} {
    zoom: 0.7;
  }

  ${media.lg} {
    zoom: 0.6;
  }

  ${media.md2} {
    margin: 0 auto;
    flex-direction: column;
    padding-right: 0;
    row-gap: 40px;

    .content {
      width: 100%;
      padding-bottom: 30px;
      background: url(${CarImg}) no-repeat center bottom;
      background-size: 80% auto;
      mix-blend-mode: screen;
    }

    button {
      width: 300px;
    }
  }
`;

export const SocialWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: absolute;
  right: 65px;
  bottom: 20%;
  z-index: 3;

  svg {
    &.twitter:hover path:last-child {
      fill: rgba(255, 29, 0, 1);
    }

    &.instagram:hover path:last-child {
      fill: rgba(255, 195, 0, 1);
    }

    &.discord:hover path:last-child {
      fill: rgba(157, 224, 113, 1);
    }

    &.mail:hover path:last-child {
      fill: rgba(70, 76, 253, 1);
    }
  }

  ${media.xl1} {
    gap: 1.2rem;
    right: 50px;
  }

  ${media.xl} {
    gap: 1rem;
    right: 40px;
  }

  ${media.lg} {
    right: 30px;
  }

  ${media.md} {
    display: none;
  }
`;

export const SocialText = styled.div`
  color: var(--Color-3, #a9a9a9);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

export const SocialIcon = styled.img`
  width: 32px;
`;

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--Main-White, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
  border-top: 1px solid var(--stroke-color);
  width: 100%;
  position: absolute;
  bottom: 0;

  ${media.sm} {
    flex-direction: column;
  }
`;

export const ValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PlayersWrapper = styled(ValueWrapper)`
  width: 40%;
  padding: 40px;

  ${media.md2} {
    font-size: 14px;
  }

  ${media.md} {
    font-size: 12px;
  }

  ${media.sm} {
    width: 100%;
    padding: 20px;
  }
`;

export const RevenueWrapper = styled(ValueWrapper)`
  width: 60%;
  padding: 40px;
  border-left: 1px solid var(--stroke-color);

  ${media.md2} {
    font-size: 14px;
  }

  ${media.md} {
    font-size: 12px;
  }

  ${media.sm} {
    width: 100%;
    border-top: 1px solid var(--stroke-color);
    padding: 20px;
  }
`;

export const PlayersValue = styled.div`
  font-size: 48px;
  font-weight: 500;

  ${media.md2} {
    font-size: 28px;
  }

  ${media.md} {
    font-size: 22px;
  }
`;

export const RevenueValue = styled.div`
  color: var(--accent-purple);
  font-size: 44px;
  font-weight: 500;

  ${media.md2} {
    font-size: 28px;
  }

  ${media.md} {
    font-size: 22px;
  }
`;

export const HorizontalImage = styled.img`
  height: 20px;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

export const FirstBlockRightTopLandingPageWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 768px) {
    align-items: center;
  }

  @media (max-width: 1280px) {
    gap: 0;
  }
`;

export const SecondBlockTopLandingPageWrapper = styled.div`
  align-items: flex-end;
  bottom: 55px;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  position: absolute;
  width: 100%;

  /* @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  @media (max-width: 480px) {
    bottom: 15%;
  } */
`;

export const ScrollImage = styled.img`
  @media (max-width: 1280px) {
    height: 50px;
  }
`;

export const AnimationContainer = styled.div`
  position: relative;
  width: 930px;
  height: 612px;

  * {
    transition: all ease-in-out 0.4s;
  }

  .pink-bg {
  }

  .animation-bg {
    scale: 0;
  }

  &:hover {
    .coin-left,
    .coin-top-left {
      rotate: 90deg;
    }

    .coin-top-right,
    .coin-bot-right {
      rotate: -90deg;
    }

    .watermelon {
      rotate: 45deg;
    }

    .face-smile {
      rotate: -45deg;
    }

    .flying-main {
      rotate: -8deg;
    }

    .baby {
      translate: -30px 30px;
    }

    .pink-bg {
      scale: 0;
    }

    .animation-bg {
      scale: 1;
    }
  }

  ${media.md} {
    left: 230px;
  }
`;

export const AnimationItem = styled.img`
  position: absolute;
`;
