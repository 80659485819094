import styled from 'styled-components';
import { media } from 'constants/enum/breakpoints';
import { PopupButton } from '@typeform/embed-react';

export const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 60px auto;
  gap: 60px;

  ${media.xl} {
    min-height: unset;
  }

  ${media.md2} {
    margin: unset;
    gap: unset;
    height: 800px;
  }
`;

export const HangOutSocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
`;

export const HangOutSocialTitle = styled.div`
  font-size: 64px;
  color: white;
  font-weight: 600;

  span {
    color: #e961ff;
  }

  ${media.md2} {
    font-size: 22px;
  }
`;

export const HangOutSocialSubTitle = styled.div`
  color: #ececec;
  font-size: 22px;
  font-weight: 300;

  ${media.md2} {
    font-size: 16px;
  }
`;
export const HangOutSocials = styled.div`
  display: flex;
  column-gap: 30px;

  .twitter:hover {
    path {
      stroke: rgba(255, 29, 0, 1);
    }
    g rect {
      fill: rgba(255, 29, 0, 1);
    }
  }

  .instagram:hover {
    path:first-child {
      stroke: rgba(157, 224, 113, 1);
    }

    path:last-child {
      fill: rgba(157, 224, 113, 1);
    }
  }

  .discord:hover {
    path:first-child {
      stroke: rgba(70, 76, 253, 1);
    }

    path:last-child {
      fill: rgba(70, 76, 253, 1);
    }
  }
`;

export const WhitepaperText = styled.div`
  color: #ececec;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;

  span {
    color: rgba(255, 195, 0, 1);
    text-decoration-line: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  ${media.md2} {
    font-size: 22px;
  }
`;

export const HangOutSocial = styled.img`
  width: 100px;
  height: 100px;

  ${media.md2} {
    width: 70px;
    height: 70px;
  }
`;

export const HangOutLetTalkWrapper = styled.div`
  display: flex;
  background-color: rgba(249, 249, 249, 0.04);
  border-radius: 36px;
  align-items: center;
  gap: 20px;
  width: 85vw;
  margin: 0 auto;
  padding: 50px;
  position: relative;

  ${media.md2} {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }
`;

export const HangOutLetTalkTitle = styled.div`
  font-size: 32px;
  color: white;
  font-weight: 600;
  /* flex: 1; */
  width: max-content;

  ${media.xl} {
    width: 300px;
  }

  ${media.lg} {
    width: 30%;
  }

  ${media.md2} {
    font-size: 22px;
    width: 80%;
  }
`;

export const HangOutLetTalkDesciption = styled.div`
  font-size: 16px;
  color: #d7d7d7;
  text-align: center;
  width: 400px;
  flex: 1;

  ${media.md2} {
    text-align: left;
    width: 65%;
  }
`;

export const HangOutLetTalkButtonWrapper = styled(PopupButton)`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: transparent;
  width: 250px;

  img {
    transition: all ease-in-out 0.3s;
  }

  &:hover {
    img {
      scale: 1.2;
    }
  }
`;

export const HangOutLetTalkCharacter = styled.img`
  position: absolute;
  right: -50px;

  ${media.md2} {
    right: 0;
  }

  ${media.xs} {
    right: -40px;
  }
`;
