import { Button } from 'antd';
import styled from 'styled-components';
import { UpdateInformationModal } from '../UserProfile/UpdateInformationModal';
import { media } from 'constants/enum/breakpoints';
import { Link } from 'react-router-dom';
import { ReactComponent as Line } from 'assets/images/profile/gradient-line.svg';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`;

export const ModalTitle = styled(Text)`
  font-size: 22px;
  font-weight: 700;
`;

export const EmailWrapper = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  color: rgba(161, 161, 165, 1);
`;

export const EmailText = styled(Link)`
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 170, 253, 1);
  cursor: pointer;
  text-decoration-line: underline;
`;

export const ModalWrapper = styled(UpdateInformationModal)`
  & > .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    .ant-modal-body {
      padding: 0;
    }
  }
`;

export const ModalWithdrawWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ModalWithdrawContainer = styled.div`
  padding: 20px 0 10px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ModalWithdrawContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;

  ${media.sm} {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
`;

export const SubmitButton = styled(Button)<{ height?: string; width?: string }>`
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 600;
  height: 46px;
  width: 142px;
  background-color: #0a3885;
  background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
    0px 2px 4px -1px rgba(10, 70, 82, 0.12),
    0px 16px 24px 0px rgba(0, 187, 255, 0.24),
    0px 8px 8px -4px rgba(0, 187, 255, 0.12);
  border-radius: 12px;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    /* background: #15151b; */
    background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
    opacity: 0.8;
    outline: 0;
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #fff;
    border: none;
    background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
    opacity: 0.8;
    outline: 0;
  }
`;

export const CryptoWithdrawalGradientLine = styled(Line)`
  position: absolute;
  left: 0;
  top: 160px;

  ${media.sm} {
    top: 180px;
  }
`;

export const CryptoDepositGradientLine = styled(CryptoWithdrawalGradientLine)`
  top: 140px;

  ${media.sm} {
    top: 160px;
  }
`;

export const FiatDepositGradientLine = styled(CryptoWithdrawalGradientLine)`
  top: 170px;

  ${media.sm} {
    top: 190px;
  }
`;
