import { TopBanner } from 'components/Layout/Banner';
import { Footer } from 'components/Layout/Footer';
import Header from 'components/Layout/Header';
import { Env } from 'constants/enum/Env';
import { useAppSelector } from 'redux/store';
import { ThemeProvider } from 'styled-components';
import { Desktop } from 'styles/Common/HeaderDesktop.styled';

import { usePublicPermission } from '../hooks/usePublicPermission';
import { MainContent, MainLayout } from './styled';
// interface HomepageTemplateProps {
//   backgroundColor?: string;
// }

// export default function HomepageTemplate({ children }) {
//   return (
//     <>
//       <Desktop>
//         <Header />
//         <MainContent>{children}</MainContent>
//         <Footer />
//       </Desktop>
//     </>
//   );
// }

interface HomepageTemplateProps {
  theme?: string;
  children?: any;
  hasFooter?: boolean;
}

export default function HomepageTemplate({
  children,
  theme,
  hasFooter,
}: HomepageTemplateProps) {
  const { backgroundURL } = useAppSelector(root => root.template);
  const collapsed = useAppSelector(state => state.navbar.collapsed);
  const isPublicPermission = usePublicPermission();
  return (
    <>
      {/* <Desktop style={{ backgroundColor: theme }}>
        <ThemeProvider
          theme={{
            collapsed,
          }}
        >
          <Navbar theme={theme} /> */}
      <MainLayout isFull={isPublicPermission}>
        <Header theme={theme} />
        <MainContent backgroundURL={backgroundURL}>
          <TopBanner />
          {children}
        </MainContent>
        {hasFooter && <Footer theme={theme} />}
      </MainLayout>
      {/* </ThemeProvider>
      </Desktop> */}
    </>
  );
}
