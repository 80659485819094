import { useEffect, useState } from 'react';
import { getSupportedTokenList } from 'services/api';

export interface ISupportedToken {
  name: string;
  symbol: string;
  address: string;
  decimals: number;
}

export const useGetListSupportedTokenForScroll = (
  next: boolean,
  refresh?: boolean,
) => {
  const [listToken, setListToken] = useState<ISupportedToken[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      if (!total || listToken.length < total) {
        const res = await getSupportedTokenList({
          pageSize: 12,
          pageIndex: page + 1,
        });
        setPage(page + 1);
        if (res?.success) {
          setListToken([...listToken, ...(res?.payload?.data?.data ?? [])]);
          setTotal(res?.payload?.data?.totalCount);
        }
      }
      setLoading(false);
    })();
  }, [next, refresh]);
  return {
    listToken,
    loading,
  };
};
