import styled from 'styled-components';
import background from 'assets/images/register/bg-register-login.png';
import { media } from 'constants/enum/breakpoints';
import bgMobile from 'assets/images/register/bg-mobile.svg';

export const ForgotPasswordWrapper = styled.div`
  background-image: url(${background});
  background-color: #000;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  ${media.md2} {
    background: url(${bgMobile});
    background-size: cover;
    border-radius: 0 0 35px 35px;
  }
`;

export const StepWrapper = styled.div``;
