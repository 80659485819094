import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';

import {
  ButtonWrapper,
  CryptoDepositGradientLine,
  EmailText,
  EmailWrapper,
  ModalTitle,
  ModalWithdrawContainer,
  ModalWithdrawContent,
  ModalWithdrawWrapper,
  ModalWrapper,
  SubmitButton,
  Text,
} from './styled';

export const CryptoDepositConfirmation = () => {
  const open = useModalIsOpen(ApplicationModal.CRYPTO_DEPOSIT_CONFIRMATION);
  const toggleModal = useToggleModal(
    ApplicationModal.CRYPTO_DEPOSIT_CONFIRMATION,
  );
  const handleCancel = () => {
    toggleModal();
  };

  return (
    <ModalWrapper isOpen={open} onCancel={handleCancel} width="520px">
      <ModalWithdrawWrapper>
        <ModalWithdrawContainer>
          <ModalTitle>Congratulations!</ModalTitle>
          <ModalWithdrawContent>
            <Text>
              Your credits will be added to your account and should appear in
              the next few minutes. Enjoy playing at BIGA Arcade!
            </Text>
            <EmailWrapper>
              Having issues or haven&apos;t received your credits? <br />
              Please email us at{' '}
              <EmailText to="mailto:cashier@bigarcade.org">
                cashier@bigarcade.org
              </EmailText>
              .
            </EmailWrapper>
          </ModalWithdrawContent>
          <ButtonWrapper>
            <SubmitButton onClick={handleCancel}>Done</SubmitButton>
          </ButtonWrapper>
        </ModalWithdrawContainer>
      </ModalWithdrawWrapper>
      <CryptoDepositGradientLine />
    </ModalWrapper>
  );
};
